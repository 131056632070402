import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';

class MediaObject extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
    return <Row>
        <Col xs={12} sm={{span: 8, offset: 2}} lg={{span: 4, offset: 2}}>
            <Image className="full-width-img media-object-img" fluid src={this.props.src} alt={this.props.alt}/>
        </Col>
        <Col className="media-object-text" xs={12} sm={{span: 8, offset: 2}} lg={{span: 4, offset: 0}}>
            <h5 className="media-object-title">{this.props.title}</h5>
            <p>{this.props.description}</p>
            <a rel="noreferrer" target="_blank" href={this.props.link}><Button>Click here to learn more</Button></a>
        </Col>
    </Row>
    }
}

export default MediaObject;