import React from 'react';
import { NavLink } from 'react-router-dom';
import HamburgerIcon from './HamburgerIcon';
import CloseMenuIcon from './CloseMenuIcon';

// Class component, rather than functional component, is created as this component needs to contain methods to control it's own visibility.
class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.visibilityHandler = this.visibilityHandler.bind(this);
        this.state = {viewable: 0};
    }
    // Handler is instantiated that allows for the visibility state to be set.
    visibilityHandler = (visibility) => {
        this.setState({viewable: visibility })
    }
    render() {
    return <div className="d-block d-md-none">
    {/* Buttons use ternary operators to show/hide themselves */}
        <button id="openButton" className={this.state.viewable === 0 ? "nav-show" : "nav-hide"} onClick={() => this.visibilityHandler(1)}><HamburgerIcon /></button>
        <button  id="closeButton" className={this.state.viewable === 1 ? "nav-show" : "nav-hide"} onClick={() => this.visibilityHandler(0)}><CloseMenuIcon /></button>
        <div id="sidebar-menu" className={this.state.viewable === 1 ? "nav-show" : "nav-hide"}>
            <ul>
            <NavLink to="/"><li className="sidebar-nav-option">Home</li></NavLink>
            <NavLink to="/menu"><li className="sidebar-nav-option">Menu</li></NavLink>
            <NavLink to="/services"><li className="sidebar-nav-option">Services</li></NavLink>
            <NavLink to="/bookings"><li className="sidebar-nav-option">Bookings</li></NavLink>
            <NavLink to="/testimonials"><li className="sidebar-nav-option">Testimonials</li></NavLink>
            <NavLink to="/about"><li className="sidebar-nav-option">About</li></NavLink>
            {/* <NavLink to="/our-story"><li className="sidebar-nav-option">Our Story</li></NavLink> */}
            <NavLink to="/contact"><li className="sidebar-nav-option">Contact</li></NavLink>
            </ul>
        </div>
        <div className={this.state.viewable === 1 ? "nav-show blocker" : "nav-hide blocker"} onClick={() => this.visibilityHandler(0)}></div>
    </div>
    }
}

export default Sidebar