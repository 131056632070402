import React, { useRef, useState } from 'react';
import {Row, Col, Form} from 'react-bootstrap'
import emailjs from '@emailjs/browser';

export const FormComponentHighTea = () => {
  const [validated, setValidated] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_awl69to', 
        'template_k1t03tl', 
        "#booking_form", 
        'Gffvzuxt0cVj3G-Fm'
        )
        .then(
          () => {
            alert("Booking request received. If you do not receive a reply within the day please call to confirm on 0212882554. - Steven and Stephanie.");
            setValidated(false);
            e.target.reset();
          },
          (error) => {
            alert("There was a problem sending your message.");
          }
        );
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendEmail(event);
    }
    setValidated(true);
  }
  return (
    <Form className="needs-validation"
    noValidate 
    ref={form} 
    onSubmit={handleSubmit}
    validated={validated}
    id="booking_form" 
    >
  <Row>
    <Col className="form-group" sm={{span: 8, offset: 2}}>
    {/* Function to Book */}
      <label htmlFor="form_select_function"  className="form-label">What service would you like to book?</label>
      <select className="form-control" id="form_select_function" name="form_select_function" required>
        <option value={"High Tea"} default>High Tea</option>
        <option value={"Functions Hosting"}>Functions Hosting</option>
        <option value={"Venue Hire"}>Venue Hire</option>
      </select>
      <div className="invalid-feedback">
        Please select a service to book.
      </div>
    </Col>
  </Row>
  {/* Date and Time */}
  <Row>
    <Col xs={6} sm={{span: 4, offset: 2}} className="form-group">
      <label htmlFor="form-date" className="form-label">Date</label>
      <input type="date" className="form-control" id="form_date" name="form_date" required />
      <div className="invalid-feedback">
        Please input a date.
      </div>
    </Col>
    <Col xs={6} sm={4} className="form-group">
    <label htmlFor="form-select-time" className="form-label">Time</label>
      <select className="form-control" id="form_select_time" name="form_select_time" required>
        <option value={""}></option>
        <option value={"10:00am"}>10:00am</option>
        <option value={"10:30am"}>10:30am</option>
        <option value={"11:00am"}>11:00am</option>
        <option value={"11:30am"}>11:30am</option>
        <option value={"12:00pm"}>12:00pm</option>
        <option value={"12:30pm"}>12:30pm</option>
        <option value={"1:00pm"}>1:00pm</option>
        <option value={"1:30pm"}>1:30pm</option>
        <option value={"2:00pm"}>2:00pm</option>
        <option value={"2:30pm"}>2:30pm</option>
        <option value={"3:00pm"}>3:00pm</option>
      </select>
      <div className="invalid-feedback">
        Please select a time.
      </div>
    </Col>
    <Col sm={{span: 8, offset: 2}}>
      <p>We are currently closed on Mondays, and so we are unable to accept bookings made for these dates.</p>
    </Col>
  </Row>
  {/* Number of People */}
  <Row>
  <Col xs={6} sm={{span: 4, offset: 2}} className="form-group">
    <label htmlFor="form-select-people" className="form-label">Number of People</label>
      <select className="form-control" id="form_select_people" name="form_select_people" required>
        <option value={""}></option>
        <option value={"1"}>1 (Just myself)</option>
        <option value={"2"}>2</option>
        <option value={"3"}>3</option>
        <option value={"4"}>4</option>
        <option value={"5"}>5</option>
        <option value={"6"}>6</option>
        <option value={"7"}>7</option>
        <option value={"8+"}>8+</option>
      </select>
      <div className="invalid-feedback">
        Please select how many people are attending.
      </div>
    </Col>
  </Row>
  {/* First and Last Name */}
  <Row>
    <Col xs={6} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} className="form-group">
      <label htmlFor="form-first-name" className="form-label">First Name</label>
      <input type="text" className="form-control" id="form_first_name" name="form_first_name" required />
      <div className="invalid-feedback">
        Please input your first name.
      </div>
    </Col>
    <Col xs={6} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} className="form-group">
      <label htmlFor="form-last-name" className="form-label">Last Name</label>
      <input type="text" className="form-control" id="form_last_name" name="form_last_name" required />
      <div className="invalid-feedback">
        Please input your last name.
      </div>
    </Col>
  </Row>
  {/* Email */}
  <Row>
    <Col sm={{span: 8, offset: 2}} className="form-group">
    <label htmlFor="form-email" className="form-label">Email</label>
      <input type="email" className="form-control" id="form_email" name="form_email" required />
      <div className="invalid-feedback">
        Please input your email.
      </div>
    </Col>
  </Row>
  {/* Phone Number */}
  <Row>
    <Col sm={{span: 8, offset: 2}} className="form-group">
    <label htmlFor="form-phonenumber" className="form-label">Phone Number</label>
      <input type="tel" pattern="[0-9]*" className="form-control" id="form_phonenumber" name="form_phonenumber" required/>
      <div className="invalid-feedback">
        Please input your phone number.
      </div>
    </Col>
  </Row>
  {/* Submit */}
    <Col className="form-group" sm={{offset: 2}}>
      <button type="submit" className='btn btn-primary btn-submit'>SUBMIT</button>
      <div className="invalid-feedback">
        Please make sure all fields are filled before continuing.
      </div>
    </Col>
  </Form>
  );
};