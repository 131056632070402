// SVG Components seperated into their own files to then import into the App file.
// This aids readability and does not clog up the window with SVG path code, see below.
// This is the primary marque of the business, with both the logo and tagline.
function TitleLogo() {
    return <svg className="d-none d-md-block" width="395.58px" height="110px" viewBox="0 0 359.62 100">
    <g>
      <path className="cls-1" d="M.2,68.12c.89,.24,1.79,.49,2.68,.73,.17,.05,.34-.01,.44-.41,1.73-6.44,3.46-12.88,5.19-19.32,.11-.39-.01-.49-.18-.54-.87-.23-1.73-.47-2.6-.7,.07-.24,.13-.48,.2-.72,2.76,.75,5.52,1.49,8.29,2.21,4.8,1.24,6.94,3.89,5.97,7.83-1.01,4.1-4.06,5.49-9.16,4.17-1.52-.39-3.03-.79-4.55-1.2-.76,2.83-1.51,5.67-2.27,8.5-.1,.39,0,.53,.21,.58,.88,.24,1.77,.47,2.65,.7-.06,.24-.13,.48-.19,.72C4.59,70.08,2.29,69.47,0,68.84c.07-.24,.13-.48,.2-.72Zm6.48-8.66c1.51,.4,3.03,.8,4.54,1.2,4.52,1.17,7.15,0,8.06-3.68,.87-3.52-.91-5.76-5.25-6.89-1.51-.39-3.02-.79-4.54-1.19-.94,3.52-1.88,7.04-2.82,10.56Z"/>
      <path className="cls-1" d="M35.12,77.46c-1.11-.24-2.62-.47-3.23-.54,.37-1.66,.74-3.33,1.11-4.99-1.74,2.94-4.43,4.39-7.42,3.69-3.16-.73-4.67-2.67-3.83-6.12,.8-3.35,1.61-6.69,2.41-10.04,.09-.36,0-.45-.2-.5-.88-.21-1.76-.43-2.63-.64,.06-.24,.12-.48,.18-.72,1.28,.31,2.56,.62,3.85,.93-.89,3.71-1.77,7.42-2.66,11.13-.74,3.09,.51,4.61,3.1,5.22,3.75,.87,7-2.16,8.05-6.91,.44-1.98,.88-3.96,1.32-5.95,.08-.36-.04-.46-.21-.5-.88-.2-1.76-.4-2.64-.6,.05-.24,.11-.48,.16-.73,1.27,.29,2.55,.58,3.83,.86-1.08,4.88-2.16,9.76-3.24,14.64-.09,.4-.01,.55,.23,.61,.9,.2,1.8,.4,2.7,.59-.05,.24-.1,.48-.16,.73-.24-.05-.49-.11-.73-.16Z"/>
      <path className="cls-1" d="M43.34,67.72c1.75-3.06,4.38-4.55,7.3-3.97,3.33,.66,4.62,2.76,3.98,6.12-.63,3.31-1.26,6.63-1.89,9.94-.08,.4,.02,.49,.22,.53,.91,.17,1.81,.34,2.72,.51-.05,.24-.09,.49-.14,.73-2.29-.43-4.58-.86-6.87-1.31,.05-.24,.1-.49,.14-.73,.86,.17,1.72,.34,2.58,.5,.21,.04,.33,0,.4-.41,.63-3.29,1.26-6.58,1.89-9.87,.57-2.96-.41-4.69-3.23-5.25-3.66-.73-6.92,2.32-8.07,7.79-.36,1.75-.73,3.49-1.09,5.23-.08,.4,0,.49,.22,.53,.9,.19,1.81,.37,2.71,.56-.05,.24-.1,.49-.15,.73-2.33-.47-4.66-.96-6.99-1.46,.05-.24,.1-.49,.16-.73,.9,.19,1.8,.38,2.71,.57,.21,.04,.33,0,.41-.4,1.37-6.51,2.73-13.03,4.1-19.54,.09-.43,.01-.55-.19-.59-.89-.19-1.77-.37-2.66-.56,.05-.24,.1-.49,.16-.73,.24,.05,.47,.1,.71,.15,1.11,.24,2.58,.44,3.19,.5-.77,3.72-1.55,7.44-2.32,11.17Z"/>
      <path className="cls-1" d="M57.23,73.59c.92-4.89,4.78-7.68,9.47-6.86,4.59,.79,7.26,4.68,6.47,9.62-.78,4.91-4.66,7.83-9.42,7.01-4.8-.83-7.45-4.84-6.52-9.76Zm15.04,2.68s.01-.07,.02-.1c.72-4.51-1.58-8.03-5.7-8.74-4.22-.73-7.59,1.84-8.41,6.3,0,.03-.01,.07-.02,.1-.83,4.46,1.43,8.05,5.73,8.79,4.3,.74,7.67-1.87,8.39-6.35Z"/>
      <path className="cls-1" d="M72.42,83.73c.91,.15,1.82,.29,2.73,.43,.21,.03,.33-.02,.39-.42,.68-4.38,1.36-8.76,2.04-13.14,.12-.77,.02-1.02-.43-1.09-.8-.12-1.6-.25-2.39-.38,.04-.24,.08-.49,.12-.73,.24,.04,.48,.08,.72,.11,1.13,.18,2.62,.27,3.2,.33-.77,5.02-1.55,10.03-2.32,15.05-.06,.4,.03,.48,.24,.52,.91,.14,1.82,.28,2.73,.41-.04,.25-.07,.49-.11,.74-2.35-.35-4.69-.71-7.04-1.09,.04-.25,.08-.49,.12-.73Zm5.62-19.91c.08-.5,.55-.84,1.09-.76,.54,.08,.89,.55,.81,1.05-.08,.53-.55,.87-1.09,.79-.54-.08-.89-.55-.8-1.08Z"/>
      <path className="cls-1" d="M90.75,75.9c.99-7.24,6.14-10.88,11.8-10.21,4,.48,6.83,2.88,7.57,6.43,.21-1.74,.41-3.47,.62-5.21,.24,.03,.48,.05,.71,.08-.27,2.61-.55,5.23-.82,7.84-.23-.02-.46-.05-.69-.07-.49-5.29-3.38-7.78-7.46-8.26-5.16-.61-9.89,2.75-10.79,9.42,0,.03,0,.07-.01,.1-.92,6.77,2.68,11.12,8.14,11.77,4.22,.5,8.04-.9,9.46-5.78,.29,.1,.59,.2,.88,.3-1.47,5.02-5.52,6.88-10.44,6.29-6.03-.72-9.95-5.59-8.98-12.7Z"/>
      <path className="cls-1" d="M112.12,81.45c.51-4.95,4.12-8.04,8.85-7.62,4.63,.41,7.6,4.06,7.22,9.06-.37,4.96-3.99,8.19-8.8,7.76-4.84-.43-7.8-4.21-7.28-9.19Zm15.17,1.43s0-.07,0-.1c.35-4.56-2.23-7.88-6.38-8.25-4.26-.38-7.39,2.46-7.85,6.97,0,.03,0,.07-.01,.1-.46,4.51,2.08,7.9,6.42,8.29,4.34,.39,7.47-2.5,7.82-7.02Z"/>
      <path className="cls-1" d="M131.07,87.78c.29-4.01,.58-8.01,.87-12.02-.94-.07-1.88-.14-2.83-.21,.02-.22,.03-.45,.05-.67,.77,.04,1.54,.07,2.31,.11,.45,.03,.52-.06,.59-.5,.12-.98,.23-1.95,.35-2.93,.26,.02,.52,.04,.79,.06-.08,1.16-.16,2.32-.25,3.48,1.43,.1,2.87,.2,4.3,.29-.02,.25-.03,.5-.05,.74-1.46-.09-2.92-.19-4.38-.3-.29,4.01-.57,8.01-.86,12.02-.15,2.09,.63,3.03,2.54,3.16,.99,.07,1.56-.1,2.11-.27-.02,.26-.03,.52-.05,.78-.4,.11-1.12,.3-2.14,.23-2.51-.17-3.55-1.37-3.36-3.97Z"/>
      <path className="cls-1" d="M140.73,88.42c.23-4.01,.47-8.02,.7-12.03-.94-.05-1.88-.11-2.83-.17,.01-.23,.03-.45,.04-.68,.77,.03,1.54,.05,2.31,.07,.45,.03,.52-.07,.58-.51,.1-.98,.21-1.95,.31-2.93,.26,.02,.53,.03,.79,.05-.07,1.16-.13,2.32-.2,3.48,1.44,.08,2.87,.16,4.31,.23-.01,.25-.02,.5-.04,.74-1.46-.07-2.92-.15-4.38-.23-.23,4.01-.46,8.02-.68,12.03-.12,2.09,.68,3.02,2.58,3.12,.99,.05,1.56-.12,2.1-.3-.01,.26-.03,.52-.04,.78-.39,.12-1.11,.32-2.14,.26-2.51-.13-3.57-1.32-3.42-3.92Z"/>
      <path className="cls-1" d="M159.76,81.81c.1-3.35-1.47-4.98-4.92-5.11-2.79-.11-4.85,1.02-5.47,2.58,.69,.03,1.06,.42,1.03,.93-.02,.51-.45,.76-.97,.74-.59-.03-1.02-.56-.98-1.27,.1-2.1,3.05-3.82,6.42-3.69,3.99,.15,5.94,2.09,5.83,5.85-.09,3.24-.19,6.47-.28,9.71,0,.3,.06,.48,.3,.48,.92,.03,1.83,.05,2.75,.07,0,.25-.01,.5-.02,.74-1.27-.03-2.54-.07-3.81-.1-.05-.78-.05-1.73-.01-4.23-1.26,3.28-4.48,4.39-6.92,4.29-3.18-.13-5.18-1.99-5.05-4.59,.17-3.39,3.6-4.98,12.07-5.25,.01-.38,.02-.77,.04-1.15Zm-.06,1.86c-7.82,.26-10.99,1.43-11.14,4.58-.1,2.16,1.59,3.7,4.24,3.81,3.64,.15,6.72-2.44,6.84-6.63,.02-.59,.04-1.17,.05-1.76Z"/>
      <path className="cls-1" d="M177.95,82.36c0,3.38-2.68,5.68-6.61,5.63-1.19-.02-2.24-.27-3.21-.73-1.55,.35-2.55,1.17-2.57,2.28-.03,1.18,1.05,1.89,3.03,1.92,2.03,.04,4.06,.06,6.1,.08,3.59,.03,5.5,1.38,5.5,3.71,0,3.11-3.81,4.81-9.1,4.74-5-.07-7.75-1.4-7.69-3.94,.05-2.06,1.96-3.37,4.69-3.93-.05,0-.09,0-.14,0-1.97-.21-3.19-1.11-3.15-2.57,.03-1.15,1.03-2.24,2.62-2.69-1.56-1.04-2.46-2.73-2.41-4.76,.08-3.32,2.93-5.75,6.61-5.7,2.41,.03,4.37,1.06,5.43,2.76,.36-2.23,1.5-3.14,2.6-3.14,.65,0,1.17,.44,1.17,1.08,0,.54-.41,.91-.97,.91-.52,0-.83-.37-.83-.85,0-.03,0-.14,.03-.17-.69,.34-1.31,1.15-1.63,2.87,.34,.68,.55,1.56,.55,2.47Zm-7.66,9.95c-3.32,.29-5.97,1.61-6.02,3.68-.05,2.16,2.24,3.21,6.81,3.27,4.71,.06,8.21-1.26,8.21-3.97,0-1.9-1.55-2.91-4.62-2.93-1.46-.01-2.92-.03-4.38-.05Zm6.75-9.92s0-.02,0-.03c.01-3.08-2.22-5.18-5.46-5.23-3.24-.04-5.6,2.07-5.66,5.01,0,.01,0,.02,0,.03-.07,3.01,2.06,5.02,5.43,5.07,3.47,.04,5.69-1.85,5.7-4.86Z"/>
      <path className="cls-1" d="M181.38,84.86s0,.09,0,.13c0,4.26,2.83,7.62,7.18,7.57,3.75-.05,5.93-1.93,6.57-4.95,.28,.04,.56,.08,.84,.12-.66,3.3-3.19,5.53-7.4,5.58-4.85,.06-8.14-3.57-8.13-8.38,0-4.88,3.18-8.45,8.03-8.51,4.3-.05,7.34,3.02,7.45,7.52,0,.25,.01,.5,.02,.74-4.85,.11-9.7,.17-14.55,.18Zm13.59-1.01c-.09-4.03-2.67-6.77-6.53-6.73-4.13,.05-6.75,3.02-7.03,6.99,4.58,0,9.16-.06,13.74-.16-.06-.03-.12-.06-.18-.1Z"/>
      <path className="cls-1" d="M212.57,91.54c.92-.04,1.83-.09,2.75-.14,.21-.01,.31-.12,.29-.52-.35-6.82-.71-13.63-1.06-20.45-2.47,.13-4.94,.24-7.42,.34-.34,.01-.51,.12-.53,.39,.04,1.39,.09,2.77,.13,4.16-.26,.01-.53,.02-.79,.03-.07-1.76-.14-3.52-.2-5.28,6.18-.24,12.36-.56,18.53-.98,.12,1.76,.23,3.51,.35,5.27-.26,.02-.53,.03-.79,.05-.11-1.38-.23-2.77-.34-4.15-.02-.3-.2-.36-.54-.34-2.49,.16-4.99,.31-7.48,.44,.36,6.82,.73,13.63,1.09,20.45,.02,.41,.13,.5,.34,.49,.92-.05,1.83-.1,2.75-.15,.01,.25,.03,.5,.04,.74-2.36,.14-4.73,.26-7.09,.37-.01-.25-.02-.5-.04-.74Z"/>
      <path className="cls-1" d="M223.77,83.46s0,.09,0,.13c.27,4.25,3.3,7.42,7.64,7.1,3.74-.28,5.81-2.3,6.25-5.35,.28,.02,.57,.04,.85,.06-.45,3.33-2.84,5.72-7.04,6.03-4.84,.36-8.35-3.05-8.65-7.85-.3-4.87,2.64-8.64,7.48-9,4.29-.32,7.52,2.55,7.91,7.03,.02,.25,.04,.49,.06,.74-4.84,.42-9.68,.78-14.52,1.1Zm13.51-1.86c-.35-4.01-3.1-6.59-6.95-6.3-4.13,.31-6.55,3.44-6.58,7.41,4.57-.3,9.14-.64,13.71-1.03-.06-.03-.12-.06-.18-.09Z"/>
      <path className="cls-1" d="M251.67,78.32c-.36-3.33-2.14-4.73-5.58-4.39-2.78,.28-4.67,1.67-5.08,3.3,.69-.06,1.1,.27,1.15,.78,.05,.5-.34,.81-.86,.86-.59,.05-1.08-.41-1.15-1.12-.18-2.09,2.5-4.2,5.86-4.53,3.98-.4,6.17,1.26,6.58,5,.35,3.22,.7,6.44,1.04,9.66,.03,.3,.12,.47,.37,.44,.91-.1,1.83-.2,2.74-.3,.03,.25,.06,.49,.08,.74-1.27,.14-2.53,.28-3.8,.42-.15-.77-.29-1.71-.59-4.19-.8,3.42-3.85,4.96-6.28,5.2-3.17,.31-5.41-1.27-5.64-3.86-.3-3.38,2.89-5.42,11.25-6.85-.04-.38-.08-.76-.12-1.14Zm.2,1.85c-7.72,1.33-10.7,2.92-10.42,6.06,.19,2.16,2.08,3.45,4.72,3.19,3.63-.35,6.33-3.33,5.88-7.5-.06-.58-.12-1.17-.19-1.75Z"/>
      <path className="cls-1" d="M281.68,81.21c.6,2.78,1.19,3.35,3.25,3.03,.2-.03,.4-.06,.59-.09,.04,.25,.08,.49,.11,.74-.19,.03-.37,.06-.56,.09-2.87,.44-3.76-.69-4.36-3.55-.89-4.25-2.75-6.08-7.61-5.4-1.46,.2-2.92,.4-4.39,.6,.41,3.09,.82,6.17,1.23,9.26,.05,.4,.21,.48,.42,.46,.9-.12,1.8-.24,2.7-.36,.03,.25,.07,.49,.1,.74-2.34,.32-4.68,.63-7.02,.93-.03-.25-.06-.49-.09-.74,.91-.12,1.82-.23,2.74-.35,.18-.02,.3-.14,.25-.55-.87-6.61-1.74-13.22-2.6-19.83-.05-.4-.2-.45-.37-.43-.88,.12-1.77,.23-2.65,.34-.03-.25-.06-.49-.09-.74,2.93-.37,5.86-.76,8.79-1.17,5.05-.71,7.88,.79,8.46,4.61,.48,3.11-.8,5.37-4.55,6.49,3.35,.3,4.91,2.42,5.66,5.94Zm-8.34-6c5-.7,6.83-2.84,6.31-6.29-.5-3.31-2.89-4.66-7.44-4.01-1.65,.23-3.31,.46-4.96,.68,.45,3.42,.91,6.84,1.36,10.27,1.58-.21,3.15-.42,4.73-.64Z"/>
      <path className="cls-1" d="M284.5,76.8c-.76-4.92,1.95-8.83,6.65-9.62,4.6-.78,8.4,2,9.3,6.93,.89,4.89-1.8,8.93-6.57,9.74-4.8,.81-8.63-2.1-9.39-7.05Zm15.07-2.46s-.01-.07-.02-.1c-.81-4.5-4.16-7.05-8.28-6.36-4.23,.71-6.54,4.25-5.84,8.74,0,.03,.01,.07,.02,.1,.7,4.48,4.02,7.12,8.32,6.39,4.3-.73,6.61-4.31,5.8-8.77Z"/>
      <path className="cls-1" d="M302.16,73.8c-.89-4.9,1.72-8.88,6.4-9.79,4.58-.9,8.46,1.77,9.49,6.68,1.02,4.86-1.57,8.98-6.31,9.91-4.78,.94-8.69-1.87-9.58-6.79Zm15.02-2.87s-.01-.07-.02-.1c-.93-4.47-4.35-6.94-8.46-6.13-4.21,.82-6.43,4.43-5.62,8.89,0,.03,.01,.07,.02,.1,.82,4.46,4.21,7.01,8.5,6.17,4.29-.84,6.5-4.49,5.58-8.93Z"/>
      <path className="cls-1" d="M331.4,63.94c.12-3.22,1.48-5.17,4.14-5.81,3.03-.73,5.25,.71,6.07,3.99,.8,3.25,1.61,6.5,2.41,9.76,.08,.33,.18,.44,.42,.38,.9-.22,1.79-.45,2.69-.67,.06,.24,.12,.48,.18,.72-2.28,.57-4.56,1.14-6.84,1.69-.06-.24-.12-.48-.17-.72,.86-.21,1.73-.42,2.59-.63,.21-.05,.28-.21,.2-.54-.79-3.23-1.59-6.46-2.38-9.7-.71-2.89-2.48-4.13-5.02-3.52-3.24,.77-4.4,3.92-3.21,9.1,.49,2.1,.97,4.2,1.46,6.3,.08,.33,.21,.44,.45,.38,.9-.21,1.8-.42,2.7-.63,.06,.24,.11,.48,.17,.72-2.32,.55-4.64,1.08-6.96,1.61-.05-.24-.11-.48-.16-.73,.89-.2,1.78-.4,2.67-.61,.24-.06,.28-.2,.2-.53-.75-3.24-1.49-6.49-2.24-9.73-.67-2.9-2.39-4.17-4.92-3.6-3.25,.72-4.46,3.85-3.34,9.05,.45,2.11,.91,4.21,1.36,6.32,.07,.33,.17,.45,.41,.4,.91-.2,1.83-.4,2.74-.6,.05,.24,.11,.48,.16,.73-2.34,.51-4.68,1.02-7.02,1.51-.05-.24-.1-.49-.15-.73,.9-.19,1.81-.38,2.71-.57,.24-.05,.28-.2,.21-.53-.96-4.5-1.93-9-2.89-13.5-.08-.36-.17-.48-.41-.43-.88,.19-1.77,.37-2.65,.56-.05-.24-.1-.49-.15-.73,.23-.05,.45-.09,.68-.14,1.15-.24,2.58-.69,3.15-.84,.28,1.43,.57,2.86,.85,4.28,.19-3.09,1.56-4.94,4.2-5.53,2.81-.63,4.84,.63,5.69,3.53Z"/>
      <path className="cls-1" d="M347.79,66.96c.26-.07,.52-.13,.79-.2,.28,1.08,.55,2.16,.83,3.25,1.35,.42,3.38,.42,5.27-.08,3.13-.83,4.5-2.61,3.89-4.83-.67-2.45-3.02-2.51-5.89-2.34-2.9,.21-5.81,.18-6.55-2.74-.64-2.53,1.08-4.78,4.37-5.64,2.18-.58,3.9-.48,5.6,.04,.33,1.22,.66,2.44,1,3.66-.26,.07-.52,.14-.78,.21-.29-1.07-.58-2.13-.87-3.2-1.42-.42-2.84-.49-4.76,.01-2.86,.75-4.27,2.51-3.73,4.64,.65,2.56,3.15,2.37,5.89,2.17,3.18-.21,5.83,.11,6.6,2.92,.73,2.64-.91,4.89-4.57,5.85-2.14,.57-4.46,.54-6.14-.01-.31-1.24-.63-2.47-.94-3.71Z"/>
    </g>
    <g>
      <path className="cls-2" d="M188.81,24.72c-3.06,0-6.39-2.44-7.36-3.21-1.24,.98-6.3,4.68-9.77,2.57-1.37-.83-2.09-1.76-2.14-2.75-.02-.46,.1-.87,.27-1.21-2.08,.22-5.09-.48-6.12-2.14-.52-.84-.72-2.2,1.18-4-1.9-1.8-1.71-3.16-1.18-4,1.03-1.66,4.04-2.36,6.12-2.14-.17-.34-.3-.74-.27-1.21,.05-1,.77-1.92,2.14-2.75,3.47-2.11,8.54,1.59,9.77,2.57,1.24-.98,6.3-4.68,9.77-2.57,1.37,.83,2.09,1.76,2.14,2.75,.03,.46-.1,.87-.27,1.21,2.07-.21,5.11,.51,6.12,2.13,.53,.84,.72,2.2-1.18,4,1.9,1.8,1.71,3.16,1.18,4-1.02,1.63-4.06,2.34-6.12,2.13,.17,.34,.3,.75,.28,1.21-.05,1-.77,1.92-2.14,2.75-.75,.45-1.57,.64-2.41,.64Zm-14.99-6.99l-2.68,2.05s-.82,.65-.77,1.52c.04,.69,.64,1.41,1.74,2.07,3.38,2.05,9.01-2.67,9.06-2.71l.27-.23,.27,.23c.06,.05,5.68,4.77,9.06,2.71,1.1-.67,1.7-1.39,1.74-2.08,.05-.86-.77-1.5-.77-1.51l-2.71-2.08,3.14,1.36c1.48,.64,5.31,.11,6.33-1.52,.69-1.11-.36-2.37-1.37-3.24l-.37-.32,.37-.32c1.01-.87,2.06-2.13,1.37-3.24-1.02-1.63-4.85-2.16-6.33-1.52l-3.14,1.36,2.72-2.08s.82-.65,.77-1.52c-.04-.69-.64-1.41-1.74-2.07-3.38-2.05-9.01,2.67-9.06,2.71l-.27,.23-.27-.23c-.06-.05-5.69-4.77-9.06-2.71-1.1,.67-1.7,1.38-1.74,2.07-.05,.86,.77,1.51,.78,1.52l2.58,2-3-1.29c-1.64-.7-5.39,0-6.33,1.52-.69,1.11,.36,2.37,1.37,3.23l.37,.32-.37,.32c-1.01,.86-2.06,2.13-1.37,3.23,.94,1.52,4.69,2.22,6.33,1.52l3.1-1.33Z"/>
      <path className="cls-2" d="M188.89,27.96c-2.79,0-5.55-1.35-7.44-2.54-1.89,1.19-4.65,2.54-7.44,2.54-1.5,0-2.86-.37-4.06-1.1-1.69-1.03-2.82-2.28-3.34-3.72-3.15-.59-5.43-2.21-6.26-4.47-.4-1.08-.64-2.75,.41-4.69-1.05-1.94-.8-3.61-.41-4.69,.84-2.26,3.11-3.88,6.26-4.47,.53-1.44,1.65-2.69,3.35-3.72,1.2-.73,2.57-1.1,4.06-1.1,2.79,0,5.55,1.35,7.44,2.54,1.89-1.19,4.65-2.54,7.44-2.54,1.49,0,2.86,.37,4.06,1.1,1.69,1.03,2.82,2.28,3.35,3.72,3.15,.59,5.43,2.21,6.26,4.47,.4,1.07,.64,2.75-.41,4.69,1.05,1.94,.8,3.61,.41,4.69-.84,2.26-3.11,3.88-6.26,4.47-.53,1.44-1.65,2.7-3.35,3.72-1.2,.73-2.57,1.1-4.06,1.1Zm-7.44-3.54l.23,.15c1.8,1.17,4.51,2.55,7.21,2.55,1.34,0,2.56-.33,3.63-.98,1.61-.98,2.64-2.16,3.06-3.5l.08-.25,.25-.04c2.93-.49,5.12-1.98,5.86-3.98,.35-.94,.55-2.44-.46-4.19l-.12-.21,.12-.21c1.02-1.75,.81-3.24,.46-4.19-.74-2-2.93-3.49-5.86-3.98l-.25-.04-.08-.25c-.42-1.35-1.45-2.53-3.06-3.5-1.07-.65-2.29-.98-3.63-.98-2.7,0-5.41,1.39-7.21,2.55l-.23,.15-.23-.15c-1.8-1.17-4.52-2.55-7.21-2.55-1.34,0-2.56,.33-3.63,.98-1.61,.98-2.64,2.16-3.06,3.5l-.08,.25-.25,.04c-2.93,.49-5.12,1.98-5.86,3.98-.35,.94-.55,2.44,.46,4.19l.12,.21-.12,.21c-1.02,1.75-.81,3.24-.46,4.19,.74,2,2.93,3.49,5.86,3.98l.25,.04,.08,.25c.42,1.35,1.45,2.52,3.06,3.5,1.07,.65,2.29,.98,3.63,.98,2.7,0,5.41-1.39,7.21-2.55l.23-.15Z"/>
      <path className="cls-2" d="M192.37,39.98v-.84c2.25,0,4.12-.85,5.27-2.39,1.49-1.99,1.69-5.01,.58-8.73-.17-.53,1.04-3.02,3.6-7.4l.72,.42c-1.69,2.88-3.44,6.18-3.51,6.77,1.48,5.01,.42,7.9-.73,9.44-1.31,1.76-3.42,2.73-5.94,2.73Zm6.66-12.22s0,0,0,.02c0,0,0-.01,0-.02Z"/>
      <path className="cls-2" d="M176.75,44.43c-1.23,0-2.36-.34-3.4-1.01-3.84-2.51-4.94-9.01-4.66-11.77,.26-2.29-2.37-8.74-2.4-8.8l.78-.32c.11,.27,2.75,6.71,2.46,9.21-.27,2.58,.74,8.66,4.28,10.97,2.01,1.31,4.51,1.14,7.43-.52l.21-.12,.21,.12c2.83,1.63,5.26,1.83,7.21,.59,3.48-2.21,4.54-8.39,4.32-11.05-.24-2.49,2.52-8.93,2.64-9.2l.77,.33c-.03,.06-2.79,6.51-2.58,8.8,.24,2.93-.89,9.41-4.71,11.84-2.18,1.39-4.83,1.24-7.87-.45-1.69,.93-3.26,1.39-4.7,1.39Z"/>
      <rect className="cls-2" x="181.03" y="24.92" width=".84" height="17.34"/>
      <path className="cls-2" d="M170.53,39.98c-2.52,0-4.63-.97-5.94-2.73-1.15-1.54-2.21-4.43-.73-9.44-.07-.6-1.82-3.89-3.51-6.77l.72-.42c2.56,4.38,3.77,6.87,3.59,7.41-1.1,3.71-.9,6.73,.59,8.72,1.15,1.54,3.02,2.39,5.27,2.39v.84Zm-6.66-12.2s0,0,0,.01c0,0,0,0,0-.01Z"/>
      <rect className="cls-2" x="181.03" y="42.27" width=".84" height="11.39"/>
      <path className="cls-2" d="M195.48,62.1c-.63,0-1.25-.04-1.88-.12-7.7-1.01-12.32-7.8-12.51-8.09l-.21-.31,.28-.24c13.06-11.25,27.27,2.22,27.41,2.36l.33,.32-.34,.3c-4.36,3.85-8.76,5.79-13.08,5.79Zm-13.47-8.38c.99,1.33,5.29,6.59,11.71,7.43,4.57,.6,9.25-1.14,13.94-5.16-2.19-1.97-14.2-11.79-25.65-2.27Z"/>
      <path className="cls-2" d="M167.41,62.1c-4.33,0-8.72-1.94-13.08-5.79l-.34-.3,.33-.32c.14-.14,14.34-13.61,27.41-2.36l.28,.24-.21,.31c-.19,.29-4.81,7.09-12.51,8.09-.62,.08-1.25,.12-1.88,.12Zm-12.18-6.11c4.69,4.02,9.37,5.76,13.94,5.16,6.42-.84,10.72-6.1,11.71-7.43-11.45-9.52-23.45,.3-25.65,2.27Z"/>
      <rect className="cls-2" x="181.03" y="7" width=".84" height="13.97"/>
      <rect className="cls-2" x="165.49" y="13.61" width="31.91" height=".84"/>
      <rect className="cls-2" x="181.45" y="53.24" width="11.8" height=".84"/>
      <rect className="cls-2" x="192.09" y="46.76" width="8.5" height=".84" transform="translate(-.34 92.9) rotate(-26.6)"/>
      <rect className="cls-2" x="169.65" y="53.24" width="11.79" height=".84"/>
      <rect className="cls-2" x="166.13" y="42.92" width=".84" height="8.5" transform="translate(49.79 174.98) rotate(-63.4)"/>
      <path className="cls-2" d="M202.47,52.39l-.14-.07c-3.84-2.05-7.59-2.92-11.16-2.58l-.68,.06,.25-.64c5.8-14.72,23.81-11.02,23.99-10.98l.44,.1-.13,.43c-2.29,7.62-6.46,12.21-12.42,13.64l-.15,.04Zm-9.79-3.56c3.2,0,6.53,.9,9.9,2.67,5.44-1.36,9.32-5.59,11.52-12.57-2.73-.45-17.06-2.17-22.32,9.93,.3-.02,.6-.02,.91-.02Z"/>
      <path className="cls-2" d="M160.43,52.39l-.15-.04c-5.95-1.42-10.13-6.01-12.42-13.64l-.13-.43,.44-.1c.18-.04,18.18-3.74,23.99,10.98l.25,.64-.68-.06c-3.57-.34-7.32,.53-11.16,2.58l-.14,.07Zm-11.63-13.46c2.2,6.98,6.07,11.21,11.52,12.57,3.69-1.94,7.32-2.83,10.81-2.65-5.27-12.09-19.59-10.37-22.32-9.93Z"/>
    </g>
  </svg>
}

export default TitleLogo