import {Row, Col, Container, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
    return <Container  fluid>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h2>WELCOME</h2>
      <div className="line"/>
    </Col>
  </Row>
  <Row>
    <Col xs={12} sm={{span: 8, offset: 2}} lg={{span: 2, offset: 3}}>
      <p className="text-lg-left">Just north of Auckland sits Puhoi Village, and at it's heart, the Puhoi Cottage Tea Rooms.</p>
    </Col>
    <Col xs={12} sm={{span: 4, offset: 2}} lg={{span: 2, offset: 0}}>
    <p className="text-lg-left">Established in 1972 along the Puhoi River, the Tea Rooms have long been part of the fabric of Puhoi, and are famous for their high teas served in the Devonshire tradition.</p>
    </Col>
    
    <Col xs={12} sm={4} lg={{span: 2, offset: 0}}>
    <p className="text-lg-left">Under new ownership, Steven and Stephanie Roach are excited to welcome you to the Puhoi Cottage Tea Rooms.<br></br>We can't wait to meet you!</p>
    </Col>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h3 className="title-morethanone title-learnmore">Learn More</h3>
      <div className="line"/>
    </Col>
  </Row>
  <Row>
  {/* Nav Buttons Container */}
    {/* Menu Button */}
    <Col className="main-btn main-menu-btn" xs={6} sm={{span: 4, offset: 2}} lg={{span: 3, offset: 3}} xl={{span: 2, offset: 3}} xxl={{span: 2, offset: 2}}>
    <Link to="/menu">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonMenu.webp" alt="Button for the Menu Page."/>
    <div className="title">
    <div className="line" />
      <h4>MENU</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
    {/* Services Button */}
    <Col className="main-btn main-services-btn" xs={6} sm={{span: 4, offset: 0}} lg={{span: 3, offset: 0}} xl={{span: 2, offset: 0}}>
    <Link to="/services">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonServices.webp" alt="Button for the Services Page."/>
    <div className="title">
    <div className="line" />
      <h4>SERVICES</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
    {/* Bookings Button */}
    <Col className="main-btn main-bookings-btn" xs={6} sm={{span: 4, offset: 2}} lg={{span: 3, offset: 3}} xl={{span: 2, offset: 0}}>
    <Link to="/bookings">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonBookings.webp" alt="Button for the Bookings Page."/>
    <div className="title">
    <div className="line" />
      <h4>BOOKINGS</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
    {/* Media Button */}
    <Col className="main-btn main-media-btn" xs={6} sm={{span: 4, offset: 0}} lg={{span: 3, offset: 0}} xl={{span: 2, offset: 3}} xxl={{span: 2, offset: 0}}>
    <Link to="/testimonials">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonMedia.webp" alt="Button for the Media Page."/>
    <div className="title">
    <div className="line" />
      <h4>TESTIMONIALS</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
    {/* About Button */}
    <Col className="main-btn main-about-btn" xs={6} sm={{span: 4, offset: 2}} lg={{span: 3, offset: 3}} xl={{span: 2, offset: 0}} xxl={{span: 2, offset: 4}}>
    <Link to="/about">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonAbout.webp" alt="Button for the About Page."/>
    <div className="title">
    <div className="line" />
      <h4>ABOUT</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
    {/* Our Story Button */}
    {/* <Col className="main-btn main-ourstory-btn" xs={6} sm={{span: 4, offset: 0}} lg={{span: 3, offset: 0}} xl={{span: 2, offset: 0}} xxl={{span: 2, offset: 0}}>
    <Link to="/our-story">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonOurStory.webp" alt="Button for the Our Story Page."/>
    <div className="title">
    <div className="line" />
      <h4 className="title-morethanone">OUR STORY</h4>
    <div className="line" />
    </div>
    </Link>
    </Col> */}
    {/* Contact Button */}
      <Col className="main-btn main-contact-btn" xs={6} sm={4} lg={3} xl={2}>
    <Link to="/contact">
    <Image src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/homebuttonContact.webp" alt="Button for the Contact Page."/>
    <div className="title">
    <div className="line" />
      <h4>CONTACT</h4>
    <div className="line" />
    </div>
    </Link>
    </Col>
  </Row>
  </Container>
}

export default Home