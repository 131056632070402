import React from "react"

class ItemTabbed extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div>
        <p className="menu-item-description">{this.props.description}</p>
        <p className="menu-item-amends">{this.props.amends}</p>
        </div>
    }
}

export default ItemTabbed