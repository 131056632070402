// SVG Components seperated into their own files to then import into the App file.
// This aids readability and does not clog up the window with SVG path code, see below.
// This is the blurb / extended tagline of the business, no logo.
function Blurb() {
    return <svg width="170px" height="80px" viewBox="0 0 210.76 100">
    <g>
      <path className="cls-1" d="M.94,77.19h1.15c.18,0,.25-.07,.25-.41v-9.29c0-.32-.07-.39-.25-.39H.94v-.83H5.4c4.07,0,6.12,2.07,6.12,5.91s-2.16,5.84-6.28,5.84H.94v-.83Zm4.39,0c3.36,0,4.74-1.73,4.74-4.79v-.44c0-3.2-1.33-4.85-4.6-4.85h-1.79v10.08h1.65Z"/>
      <path className="cls-1" d="M14.08,73.93c.02,1.96,1.13,3.41,3.08,3.41,1.61,0,2.53-.85,2.88-2.19l.87,.3c-.39,1.57-1.63,2.76-3.82,2.76-2.76,0-4.35-1.89-4.35-4.48s1.72-4.48,4.28-4.48,3.96,1.68,3.96,4.26v.41h-6.9Zm0-.78h5.56c-.02-1.93-.96-3.06-2.64-3.06s-2.78,1.18-2.92,3.06Z"/>
      <path className="cls-1" d="M26.31,76.62l2.41-5.93c.09-.27-.07-.41-.28-.41h-1.06v-.83h3.59v.83h-.87c-.18,0-.32,.07-.44,.37l-3.1,7.47h-.9l-3.13-7.47c-.12-.32-.23-.37-.39-.37h-.92v-.83h3.89v.83h-.97c-.25,0-.39,.11-.27,.39,.09,.28,1.77,4.33,2.44,5.94Z"/>
      <path className="cls-1" d="M31.07,73.74c0-2.65,1.75-4.48,4.33-4.48s4.28,1.81,4.28,4.48-1.75,4.48-4.32,4.48-4.3-1.8-4.3-4.48Zm7.31,.07v-.14c0-2.21-1.12-3.54-2.97-3.54s-3.01,1.33-3.01,3.54v.14c0,2.21,1.1,3.54,2.97,3.54s3.01-1.34,3.01-3.54Z"/>
      <path className="cls-1" d="M43.23,71.56c.57-1.5,1.65-2.3,3.08-2.3,1.79,0,2.71,1.01,2.71,2.95v4.6c0,.32,.09,.37,.23,.37h1.12v.83h-3.93v-.83h1.01c.16,0,.25-.05,.25-.37v-4.55c0-1.42-.55-2.07-1.79-2.07-1.58,0-2.65,1.45-2.65,3.95v2.67c0,.32,.09,.37,.23,.37h1.1v.83h-4v-.83h1.11c.14,0,.23-.05,.23-.37v-6.1c0-.34-.05-.42-.23-.42h-1.11v-.83h.73c.8,0,1.59-.07,1.93-.14v2.25Z"/>
      <path className="cls-1" d="M51.23,75.26h1.03v1.7c.5,.27,1.24,.44,2.09,.44,1.38,0,2.14-.55,2.14-1.56,0-1.13-1.03-1.4-2.3-1.65-1.49-.3-2.97-.72-2.97-2.41,0-1.52,1.26-2.53,3.29-2.53,1.22,0,2.26,.34,2.95,.76v2.16h-1.03v-1.65c-.44-.28-1.1-.46-1.89-.46-1.31,0-2.09,.55-2.09,1.5,0,1.17,1.08,1.33,2.34,1.56,1.56,.32,2.94,.81,2.94,2.5,0,1.56-1.22,2.58-3.36,2.58-1.19,0-2.34-.34-3.13-.78v-2.18Z"/>
      <path className="cls-1" d="M61.19,71.49c.58-1.45,1.66-2.23,3.06-2.23,1.82,0,2.71,.97,2.71,2.9v4.65c0,.32,.09,.37,.23,.37h1.11v.83h-3.93v-.83h1.03c.14,0,.23-.05,.23-.37v-4.58c0-1.38-.51-2.03-1.79-2.03-1.57,0-2.65,1.49-2.65,3.95v2.67c0,.32,.09,.37,.25,.37h1.06v.83h-3.98v-.83h1.12c.14,0,.23-.05,.23-.37v-9.29c0-.34-.05-.42-.23-.42h-1.13v-.83h.76c.8,0,1.59-.07,1.93-.14v5.36Z"/>
      <path className="cls-1" d="M68.86,77.19h1.11c.16,0,.23-.07,.23-.37v-5.82c0-.55-.09-.71-.39-.71h-.96v-.83h.76c.78,0,1.57-.07,1.93-.14v7.5c0,.3,.07,.37,.23,.37h1.1v.83h-4.02v-.83Zm1.04-10.17c0-.51,.37-.92,.94-.92s.96,.41,.96,.92-.41,.94-.96,.94-.94-.41-.94-.94Z"/>
      <path className="cls-1" d="M76.42,71.35c.44-1.5,1.38-2.09,2.39-2.09,1.18,0,1.82,.76,1.82,1.7,0,.76-.41,1.24-1.06,1.24-.44,0-.8-.34-.8-.76,0-.39,.28-.69,.67-.76-.07-.25-.34-.53-.88-.53-1.43,0-2.11,1.5-2.11,3.66v3.01c0,.32,.09,.37,.25,.37h1.19v.83h-4.11v-.83h1.12c.14,0,.23-.05,.23-.37v-6.1c0-.34-.05-.42-.23-.42h-1.13v-.83h.73c.78,0,1.57-.07,1.93-.14v2.03Z"/>
      <path className="cls-1" d="M82.56,73.93c.02,1.96,1.13,3.41,3.08,3.41,1.61,0,2.53-.85,2.88-2.19l.87,.3c-.39,1.57-1.63,2.76-3.82,2.76-2.76,0-4.35-1.89-4.35-4.48s1.72-4.48,4.28-4.48,3.96,1.68,3.96,4.26v.41h-6.9Zm0-.78h5.56c-.02-1.93-.96-3.06-2.64-3.06s-2.78,1.18-2.92,3.06Z"/>
      <path className="cls-1" d="M97.89,77.19h1.19c.18,0,.25-.07,.25-.41v-9.71h-2.69c-.41,0-.62,.09-.65,.41l-.05,1.63h-.94v-2.83h10v2.83h-.92l-.07-1.63c-.02-.34-.21-.41-.65-.41h-2.71v9.71c0,.34,.07,.41,.25,.41h1.19v.83h-4.18v-.83Z"/>
      <path className="cls-1" d="M105.74,73.93c.02,1.96,1.13,3.41,3.08,3.41,1.61,0,2.53-.85,2.88-2.19l.87,.3c-.39,1.57-1.63,2.76-3.82,2.76-2.76,0-4.35-1.89-4.35-4.48s1.72-4.48,4.28-4.48,3.96,1.68,3.96,4.26v.41h-6.9Zm0-.78h5.56c-.02-1.93-.96-3.06-2.64-3.06s-2.78,1.18-2.92,3.06Z"/>
      <path className="cls-1" d="M115.42,70.85c.35,.11,.57,.41,.57,.8,0,.51-.39,.83-.89,.83-.58,0-.97-.41-.97-1.03,0-1.36,1.66-2.19,3.63-2.19,2.34,0,3.4,.92,3.4,2.97v4.58c0,.27,.05,.37,.23,.37h1.1v.83h-2.34c-.14-.5-.23-1.01-.28-1.82-.48,1.22-1.79,2.02-3.27,2.02-1.63,0-2.65-.94-2.65-2.26,0-1.95,1.89-2.78,5.89-3.2v-.5c0-1.52-.69-2.16-2.25-2.16-1.04,0-1.77,.28-2.16,.76Zm4.41,3.34v-.74c-3.15,.32-4.6,.88-4.6,2.34,0,.92,.71,1.54,1.77,1.54,1.7,0,2.81-1.27,2.83-3.13Z"/>
      <path className="cls-1" d="M123.27,75.26h1.03v1.7c.5,.27,1.24,.44,2.09,.44,1.38,0,2.14-.55,2.14-1.56,0-1.13-1.03-1.4-2.3-1.65-1.49-.3-2.97-.72-2.97-2.41,0-1.52,1.26-2.53,3.29-2.53,1.22,0,2.26,.34,2.95,.76v2.16h-1.03v-1.65c-.44-.28-1.1-.46-1.89-.46-1.31,0-2.09,.55-2.09,1.5,0,1.17,1.08,1.33,2.34,1.56,1.56,.32,2.94,.81,2.94,2.5,0,1.56-1.22,2.58-3.36,2.58-1.19,0-2.34-.34-3.13-.78v-2.18Z"/>
      <path className="cls-1" d="M137.03,70.85c.35,.11,.57,.41,.57,.8,0,.51-.39,.83-.89,.83-.58,0-.97-.41-.97-1.03,0-1.36,1.66-2.19,3.63-2.19,2.34,0,3.4,.92,3.4,2.97v4.58c0,.27,.05,.37,.23,.37h1.1v.83h-2.34c-.14-.5-.23-1.01-.28-1.82-.48,1.22-1.79,2.02-3.27,2.02-1.63,0-2.65-.94-2.65-2.26,0-1.95,1.89-2.78,5.89-3.2v-.5c0-1.52-.69-2.16-2.25-2.16-1.04,0-1.77,.28-2.16,.76Zm4.41,3.34v-.74c-3.15,.32-4.6,.88-4.6,2.34,0,.92,.71,1.54,1.77,1.54,1.7,0,2.81-1.27,2.83-3.13Z"/>
      <path className="cls-1" d="M147.47,71.56c.57-1.5,1.65-2.3,3.08-2.3,1.79,0,2.71,1.01,2.71,2.95v4.6c0,.32,.09,.37,.23,.37h1.12v.83h-3.93v-.83h1.01c.16,0,.25-.05,.25-.37v-4.55c0-1.42-.55-2.07-1.79-2.07-1.58,0-2.65,1.45-2.65,3.95v2.67c0,.32,.09,.37,.23,.37h1.1v.83h-4v-.83h1.11c.14,0,.23-.05,.23-.37v-6.1c0-.34-.05-.42-.23-.42h-1.11v-.83h.73c.8,0,1.59-.07,1.93-.14v2.25Z"/>
      <path className="cls-1" d="M155.43,73.74c0-2.71,1.59-4.48,3.96-4.48,1.54,0,2.67,.78,3.24,2.07v-3.8c0-.34-.07-.42-.25-.42h-1.13v-.83h.78c.78,0,1.57-.07,1.93-.14v10.69c0,.32,.07,.37,.23,.37h1.1v.83h-2.49c-.05-.41-.11-1.13-.12-1.89-.57,1.29-1.7,2.09-3.26,2.09-2.41,0-3.98-1.75-3.98-4.48Zm7.18,.09v-.18c0-2.16-1.2-3.47-2.88-3.47-1.77,0-2.94,1.24-2.94,3.45v.21c0,2.21,1.17,3.45,2.94,3.45s2.88-1.29,2.88-3.47Z"/>
      <path className="cls-1" d="M170.45,77.19h1.18c.18,0,.25-.07,.25-.41v-9.29c0-.32-.07-.39-.25-.39h-1.18v-.83h3.4l3.68,9.71,3.86-9.71h3.31v.83h-1.15c-.18,0-.25,.07-.25,.39v9.29c0,.34,.07,.41,.25,.41h1.15v.83h-4.16v-.83h1.17c.18,0,.27-.07,.27-.41v-9.54l-4.28,10.79h-.87l-4.02-10.69v9.43c0,.34,.09,.41,.27,.41h1.17v.83h-3.79v-.83Z"/>
      <path className="cls-1" d="M185.55,73.74c0-2.65,1.75-4.48,4.33-4.48s4.28,1.81,4.28,4.48-1.75,4.48-4.32,4.48-4.3-1.8-4.3-4.48Zm7.31,.07v-.14c0-2.21-1.12-3.54-2.97-3.54s-3.01,1.33-3.01,3.54v.14c0,2.21,1.1,3.54,2.97,3.54s3.01-1.34,3.01-3.54Z"/>
      <path className="cls-1" d="M197.72,71.35c.44-1.5,1.38-2.09,2.39-2.09,1.18,0,1.82,.76,1.82,1.7,0,.76-.41,1.24-1.06,1.24-.44,0-.8-.34-.8-.76,0-.39,.28-.69,.67-.76-.07-.25-.34-.53-.88-.53-1.43,0-2.11,1.5-2.11,3.66v3.01c0,.32,.09,.37,.25,.37h1.19v.83h-4.11v-.83h1.12c.14,0,.23-.05,.23-.37v-6.1c0-.34-.05-.42-.23-.42h-1.13v-.83h.73c.78,0,1.57-.07,1.93-.14v2.03Z"/>
      <path className="cls-1" d="M203.86,73.93c.02,1.96,1.13,3.41,3.08,3.41,1.61,0,2.53-.85,2.88-2.19l.87,.3c-.39,1.57-1.63,2.76-3.82,2.76-2.76,0-4.35-1.89-4.35-4.48s1.72-4.48,4.28-4.48,3.96,1.68,3.96,4.26v.41h-6.9Zm0-.78h5.56c-.02-1.93-.96-3.06-2.64-3.06s-2.78,1.18-2.92,3.06Z"/>
    </g>
    <g>
      <path className="cls-1" d="M.59,98.78l.67-2.48h.42l-.62,2.26c.94,.64,2.35,.99,3.57,.99,2.18,0,3.43-1.06,3.43-2.74s-1.47-2.25-2.65-2.79c-1.52-.71-2.87-1.26-2.87-2.99s1.54-3.13,3.64-3.13c1.36,0,2.64,.41,3.54,1.08l-.67,2.49h-.42l.64-2.34c-.81-.51-1.88-.83-3.08-.83-1.89,0-3.18,1.12-3.18,2.71s1.08,1.93,2.58,2.62c1.34,.6,2.94,1.2,2.94,3.17s-1.49,3.17-3.89,3.17c-1.45,0-2.9-.42-4.05-1.18Z"/>
      <path className="cls-1" d="M10.49,98.85c0-.5,.21-1.13,.46-2.02l1.38-4.88c.05-.19,.02-.27-.11-.27h-1.72l.12-.39h.71c.57,0,1.43-.07,1.65-.09l-1.58,5.66c-.28,1.04-.46,1.52-.46,1.95,0,.53,.39,.76,.88,.76,.37,0,.67-.11,.94-.21l-.11,.39c-.21,.11-.51,.21-.88,.21-.8,0-1.29-.42-1.29-1.11Zm2.41-10c0-.27,.19-.46,.44-.46s.46,.19,.46,.46c0,.25-.21,.44-.46,.44s-.44-.19-.44-.44Z"/>
      <path className="cls-1" d="M16.42,94.8c1.15-2.53,2.48-3.66,4.03-3.66,1.34,0,2.16,.73,2.16,1.95,0,.58-.46,1.98-.88,3.34-.35,1.15-.67,2.09-.67,2.41,0,.5,.25,.74,.78,.74,.35,0,.62-.09,.99-.21l-.11,.39c-.27,.11-.58,.21-.94,.21-.73,0-1.19-.41-1.19-1.1,0-.41,.37-1.41,.74-2.6,.41-1.29,.83-2.58,.83-3.17,0-.99-.64-1.59-1.72-1.59-1.73,0-3.64,1.47-5.36,8.3h-.48l2.14-7.86c.07-.19,.04-.27-.09-.27h-1.66l.11-.39h.92c.53,0,1.1-.05,1.4-.09l-1.01,3.59Z"/>
      <path className="cls-1" d="M31.68,93.61c-.07-1.17-1.1-2.1-2.71-2.1-2.23,0-4.02,2.07-4.02,4.74,0,1.89,1.13,3.33,3.03,3.33,1.61,0,2.6-.94,3.15-2.14l.37,.18c-.57,1.18-1.66,2.35-3.52,2.35-2.14,0-3.47-1.59-3.47-3.75,0-2.83,2.02-5.08,4.46-5.08,1.96,0,3.15,1.26,3.15,2.62,0,.6-.25,.96-.69,.96-.25,0-.44-.21-.44-.44,0-.34,.27-.55,.67-.53,.02-.05,.02-.07,.02-.12Z"/>
      <path className="cls-1" d="M33.12,96.5c0-3.04,1.98-5.4,4.53-5.4,1.95,0,3.15,1.24,3.15,3.01,0,.44-.05,.96-.2,1.34h-6.97c-.05,.34-.09,.69-.09,1.04,0,1.89,1.27,3.11,3.08,3.11,1.63,0,2.69-.89,3.31-2.18l.37,.18c-.73,1.42-1.88,2.39-3.68,2.39-2.09,0-3.5-1.45-3.5-3.5Zm.58-1.43h6.55c.09-.3,.12-.62,.12-.94,0-1.59-1.06-2.64-2.72-2.64-1.88,0-3.45,1.5-3.95,3.57Z"/>
      <path className="cls-1" d="M50.73,89.53c-.53,.71-1.31,1.15-2.62,1.15h-.62l.11-.41h.6c1.82,0,2.6-1.1,3.04-2.3h.37s-3.01,11.16-3.01,11.2c-.05,.19,0,.25,.09,.25h2.3l-.11,.39h-5.45l.11-.39h2.39c.11,0,.18-.05,.23-.25l2.57-9.64Z"/>
      <path className="cls-1" d="M56.52,99.56c3.31,0,5.11-3.04,5.38-6.65-.53,1.56-2,2.71-4,2.71-2.21,0-3.68-1.45-3.68-3.45,0-2.42,1.84-4.26,4.23-4.26s3.89,1.72,3.89,4.25c0,4.46-2.26,7.82-5.82,7.82-2,0-3.4-1.1-3.4-2.58,0-.58,.28-.92,.71-.92,.25,0,.44,.18,.44,.42,0,.28-.23,.57-.73,.57,.07,1.26,1.26,2.1,2.97,2.1Zm5.29-7.89c0-2.07-1.41-3.38-3.4-3.38-2.09,0-3.73,1.68-3.73,3.87,0,1.82,1.27,3.06,3.24,3.06,2.46,0,3.89-1.88,3.89-3.56Z"/>
      <path className="cls-1" d="M72.55,88.06h.42l-.34,1.5c-.39,1.77-1.34,2.18-3.18,3.5-2.78,1.96-4.05,3.31-4.95,6.74h-.44l.04-.14c.9-3.52,2.05-4.74,5.15-6.95,1.38-.99,2.5-1.56,2.85-2.64-.32,.32-.8,.42-1.13,.42-1.98,0-2.78-2.02-5.56-2.05l-.74,2.74h-.44l.87-3.17h.27c2.97,0,3.77,2.07,5.61,2.07,.57,0,1.06-.16,1.29-.71l.3-1.33Z"/>
      <path className="cls-1" d="M74.91,91.23c0,.27-.21,.44-.46,.44-.35,0-.57-.3-.57-.74,0-1.73,1.79-3.03,4.03-3.03s3.79,1.31,3.79,3.15c0,2.07-1.15,2.83-4.03,3.52-2.48,.6-4.28,1.04-4.95,3.36l-.41,1.45h6.6c.18,0,.27-.04,.34-.21l.64-2.23h.41l-.78,2.87h-7.78l.58-1.98c.71-2.48,2.58-2.99,5.24-3.64,2.64-.64,3.7-1.26,3.7-3.13,0-1.61-1.42-2.74-3.34-2.74s-3.4,.9-3.54,2.37c.28,.02,.55,.27,.55,.55Z"/>
    </g>
    <g>
      <path className="cls-1" d="M0,20.72H2.48c.16,0,.29-.1,.29-.48V1.43c0-.38-.13-.45-.29-.45H0V.29H7.93c4.58,0,7.1,1.94,7.1,5.76s-2.39,5.92-7.16,5.92H3.6v8.28c0,.38,.13,.48,.32,.48h2.45v.7H0v-.7ZM3.6,11.27H7.86c4.23,0,6.3-1.66,6.3-5.22S12.06,.99,7.93,.99H3.6V11.27Z"/>
      <path className="cls-1" d="M32.41,21.42c-1.02,0-2.39,.1-2.93,.16v-4.81c-.95,3.06-3.02,4.94-5.76,4.94-2.9,0-4.62-1.43-4.62-4.78V7.23c0-.35-.1-.41-.29-.41h-2.48v-.7h3.63v10.76c0,2.99,1.4,4.11,3.79,4.11,3.44,0,5.7-3.44,5.7-8.02V7.23c0-.35-.13-.41-.29-.41h-2.48v-.7h3.6v14.1c0,.38,.1,.51,.32,.51h2.48v.7h-.67Z"/>
      <path className="cls-1" d="M37.73,10.85c.99-3.15,3.09-5.03,5.83-5.03,3.12,0,4.65,1.72,4.65,4.94v9.52c0,.38,.1,.45,.29,.45h2.48v.7h-6.27v-.7h2.36c.19,0,.29-.06,.29-.45V10.82c0-2.83-1.18-4.27-3.82-4.27-3.44,0-5.79,3.44-5.79,8.69v5.03c0,.38,.1,.45,.29,.45h2.48v.7h-6.4v-.7h2.48c.19,0,.29-.06,.29-.45V1.5c0-.41-.1-.51-.29-.51h-2.52V.29h.67c1.05,0,2.42-.1,2.99-.16V10.85Z"/>
      <path className="cls-1" d="M52.25,13.75c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M68.39,20.72h2.48c.19,0,.29-.06,.29-.45V7.77c0-.73-.13-.95-.54-.95h-2.23v-.7h.67c1.05,0,2.42-.13,2.96-.16v14.32c0,.38,.1,.45,.29,.45h2.48v.7h-6.4v-.7Zm2.23-19.32c0-.48,.38-.86,.89-.86s.89,.38,.89,.86c0,.51-.38,.89-.89,.89s-.89-.38-.89-.89Z"/>
      <path className="cls-1" d="M83.8,10.92C83.8,4.04,88.09,0,93.41,0,97.17,0,100.03,1.97,101.05,5.22l.06-4.94h.67V7.7h-.64c-.96-4.9-3.88-6.94-7.7-6.94-4.84,0-8.79,3.72-8.79,10.06v.1c0,6.43,3.72,10.03,8.66,10.03,3.82,0,7.1-1.69,7.93-6.4l.83,.19c-.86,4.84-4.3,6.97-8.76,6.97-5.44,0-9.52-4.04-9.52-10.79Z"/>
      <path className="cls-1" d="M103.95,13.75c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M122.48,18.14V6.81h-2.61v-.64l2.13-.06c.41,0,.48-.1,.51-.51l.13-2.77h.73v3.28h3.98v.7h-4.04v11.33c0,1.97,.76,2.8,2.48,2.8,.89,0,1.4-.19,1.88-.38v.73c-.35,.13-.99,.35-1.91,.35-2.26,0-3.28-1.05-3.28-3.5Z"/>
      <path className="cls-1" d="M131.3,18.14V6.81h-2.61v-.64l2.13-.06c.41,0,.48-.1,.51-.51l.13-2.77h.73v3.28h3.98v.7h-4.04v11.33c0,1.97,.76,2.8,2.48,2.8,.89,0,1.4-.19,1.88-.38v.73c-.35,.13-.99,.35-1.91,.35-2.26,0-3.28-1.05-3.28-3.5Z"/>
      <path className="cls-1" d="M148.87,11.14c0-3.15-1.5-4.65-4.68-4.65-2.58,0-4.43,1.15-4.94,2.64,.64,0,.99,.35,.99,.83s-.38,.73-.86,.73c-.54,0-.96-.48-.96-1.15,0-1.97,2.64-3.72,5.76-3.72,3.69,0,5.54,1.78,5.54,5.32v9.14c0,.29,.06,.45,.29,.45h2.48v.7h-3.44c-.06-.73-.1-1.62-.13-3.98-1.05,3.12-3.92,4.27-6.11,4.27-2.87,0-4.74-1.66-4.74-4.11,0-3.18,3.06-4.87,10.79-5.38v-1.08Zm0,1.75c-7.13,.48-9.96,1.75-9.96,4.71,0,2.04,1.59,3.41,3.98,3.41,3.28,0,5.99-2.52,5.99-6.46v-1.65Z"/>
      <path className="cls-1" d="M165.75,11.36c0,3.18-2.42,5.35-5.98,5.35-1.08,0-2.04-.22-2.93-.64-1.4,.35-2.29,1.15-2.29,2.2,0,1.11,.99,1.75,2.77,1.75h5.51c3.25,0,4.97,1.27,4.97,3.47,0,2.93-3.41,4.52-8.12,4.52-4.46,0-6.94-1.18-6.94-3.57,0-1.94,1.69-3.22,4.14-3.79h-.13c-1.78-.16-2.9-.99-2.9-2.35,0-1.08,.89-2.13,2.32-2.58-1.43-.96-2.29-2.52-2.29-4.43,0-3.12,2.58-5.48,5.98-5.48,2.23,0,4.04,.96,5.03,2.55,.32-2.1,1.37-2.96,2.39-2.96,.6,0,1.08,.41,1.08,1.02,0,.51-.38,.86-.89,.86-.48,0-.76-.35-.76-.8,0-.03,0-.13,.03-.16-.64,.32-1.21,1.08-1.5,2.71,.32,.64,.51,1.46,.51,2.32Zm-6.88,9.42c-2.99,.32-5.35,1.62-5.35,3.57,0,2.04,2.07,2.96,6.14,2.96s7.32-1.24,7.32-3.79c0-1.78-1.4-2.74-4.17-2.74h-3.95Zm6.05-9.39v-.03c0-2.9-2.07-4.87-5.06-4.87s-5.13,2.04-5.13,4.81v.03c0,2.83,1.97,4.68,5.03,4.68s5.16-1.78,5.16-4.62Z"/>
      <path className="cls-1" d="M170.02,13.72v.13c0,4.01,2.55,7.16,6.46,7.16,3.37,0,5.38-1.72,6.02-4.55l.76,.13c-.67,3.09-2.99,5.13-6.78,5.13-4.36,0-7.32-3.41-7.32-7.93s2.93-7.96,7.42-7.96c3.98,0,6.72,2.96,6.72,7.19v.7h-13.27Zm12.42-.8c0-3.79-2.32-6.43-5.89-6.43-3.82,0-6.24,2.8-6.49,6.53h12.54l-.16-.1Z"/>
      <path className="cls-1" d="M5.89,55.21h2.48c.19,0,.29-.1,.29-.48v-19.26H1.75c-.32,0-.48,.1-.51,.35l-.03,3.91H.48v-4.97H17.76v4.97h-.73l-.06-3.91c0-.29-.16-.35-.48-.35h-6.97v19.26c0,.38,.1,.48,.29,.48h2.48v.7H5.89v-.7Z"/>
      <path className="cls-1" d="M17.54,48.21v.13c0,4.01,2.55,7.16,6.46,7.16,3.37,0,5.38-1.72,6.02-4.55l.76,.13c-.67,3.09-2.99,5.13-6.78,5.13-4.36,0-7.32-3.41-7.32-7.93s2.93-7.96,7.42-7.96c3.98,0,6.72,2.96,6.72,7.19v.7h-13.27Zm12.42-.8c0-3.79-2.32-6.43-5.89-6.43-3.82,0-6.24,2.8-6.49,6.53h12.54l-.16-.1Z"/>
      <path className="cls-1" d="M43.97,45.63c0-3.15-1.5-4.65-4.68-4.65-2.58,0-4.43,1.15-4.94,2.64,.64,0,.99,.35,.99,.83s-.38,.73-.86,.73c-.54,0-.96-.48-.96-1.15,0-1.97,2.64-3.72,5.76-3.72,3.69,0,5.54,1.78,5.54,5.32v9.14c0,.29,.06,.45,.29,.45h2.48v.7h-3.44c-.06-.73-.1-1.62-.13-3.98-1.05,3.12-3.92,4.27-6.11,4.27-2.87,0-4.74-1.66-4.74-4.11,0-3.18,3.06-4.87,10.79-5.38v-1.08Zm0,1.75c-7.13,.48-9.96,1.75-9.96,4.71,0,2.04,1.59,3.41,3.98,3.41,3.28,0,5.99-2.52,5.99-6.46v-1.65Z"/>
      <path className="cls-1" d="M70.71,51.94c.16,2.67,.6,3.28,2.48,3.28h.54v.7h-.51c-2.61,0-3.25-1.18-3.41-3.92-.22-4.07-1.66-6.05-6.14-6.05h-4.04v8.79c0,.38,.13,.48,.32,.48h2.45v.7h-6.37v-.7h2.48c.16,0,.29-.1,.29-.48v-18.81c0-.38-.13-.45-.29-.45h-2.48v-.7h8.24c4.74,0,7.13,1.82,7.13,5.44,0,2.96-1.46,4.87-5,5.41,2.99,.73,4.11,2.93,4.3,6.3Zm-6.72-6.72c4.62,0,6.56-1.72,6.56-5s-2.01-4.74-6.27-4.74h-4.65v9.74h4.36Z"/>
      <path className="cls-1" d="M74.85,48.24c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M91.53,48.24c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M120.57,44.74c.76-2.93,2.39-4.43,4.9-4.43,2.86,0,4.52,1.82,4.52,5v9.45c0,.32,.06,.45,.29,.45h2.48v.7h-6.3v-.7h2.39c.19,0,.29-.13,.29-.45v-9.39c0-2.8-1.31-4.33-3.69-4.33-3.06,0-4.74,2.64-4.74,7.64v6.08c0,.32,.1,.45,.32,.45h2.48v.7h-6.4v-.7h2.45c.22,0,.29-.13,.29-.45v-9.39c0-2.8-1.27-4.33-3.66-4.33-3.06,0-4.74,2.64-4.74,7.64v6.08c0,.32,.06,.45,.29,.45h2.51v.7h-6.43v-.7h2.48c.22,0,.29-.13,.29-.45v-12.99c0-.35-.06-.48-.29-.48h-2.48v-.7h.64c1.08,0,2.45-.13,2.99-.16l-.06,4.11c.76-2.8,2.36-4.23,4.84-4.23,2.64,0,4.2,1.59,4.36,4.43Z"/>
      <path className="cls-1" d="M134.32,51.14h.73v3.15c1.08,.7,2.83,1.18,4.58,1.18,2.9,0,4.49-1.27,4.49-3.44,0-2.39-2.04-3.02-4.58-3.57-2.58-.51-5.13-1.21-5.13-4.04,0-2.45,2.04-4.11,5.16-4.11,2.07,0,3.57,.51,4.93,1.4v3.57h-.73v-3.12c-1.15-.73-2.39-1.15-4.2-1.15-2.71,0-4.36,1.27-4.36,3.34,0,2.48,2.23,2.9,4.68,3.37,2.83,.57,5.06,1.53,5.06,4.27,0,2.58-1.94,4.2-5.32,4.2-1.97,0-3.98-.57-5.32-1.46v-3.6Z"/>
    </g>
  </svg>
}

export default Blurb