import {Row, Col, Container} from 'react-bootstrap'
import MediaObject from '../components/MediaObject'

function Testimonials() {
    return <Container fluid>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h2>TESTIMONIALS</h2>
      <div className="line"/>
    </Col>
    </Row>
    <Row>
      <Col xs={12} sm={{span: 8, offset: 2}}>
        <p className="text-center">
          We love hearing your feedback so please consider leaving a review.
        </p>
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}}>
        <ul className="text-center">
        {/* Needs logos, out of scope for now. */}
          <li><h4><a href="https://g.page/r/CWCLBTAlj47gEAg/review">Google</a></h4></li>
          <li><h4><a href="https://www.tripadvisor.co.nz/Restaurant_Review-g1841499-d3194751-Reviews-Puhoi_Cottage_Tea_Rooms-Puhoi_North_Island.html">TripAdvisor</a></h4></li>
          <li><h4><a href="https://www.yelp.co.nz/biz/puhoi-cottage-tea-rooms-puhoi?osq=puhoi+cottage">Yelp</a></h4></li>
        </ul>
      </Col>
    </Row>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h4>Media</h4>
      <div className="line"/>
    </Col>
    </Row>
    {/* <MediaObject title="" src="" alt="" description="" link=""/> */}
    <MediaObject title="Junction Magazine" src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/junction_image_large.jpg" alt="Feature of the Puhoi Tea Rooms published in Junction Magazine" description="We recently had the opportunity to chat to Junction Magazine about our recent reopening, as well as share a few tips about how we make our famous scones." link="https://www.junctionmag.co.nz/junctionmag/2021/27/02/scones-cream?fbclid=IwAR3uCThSy-br1haf3xTujocT8KvXaKufEu1ywG_pL8SWFWX4JmVtAzC6RkY"/>
  </Container>
}

export default Testimonials