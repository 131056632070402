import {Row, Col, Container} from 'react-bootstrap'

function About() {
    return <Container fluid>
    <Row>
      <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
        <div className="line"/>
        <h2>ABOUT</h2>
        <div className="line"/>
      </Col>
    </Row>
    <Row>
    <Col xs={12} sm={{span: 8, offset: 2}}>
      <p className="text-center">Our cottage has a long and storied history.</p>
    </Col>
    </Row> 
    <Row>
      {/* <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} className="hero-image-container">
        <Image fluid className="services-img" src='https://storage.googleapis.com/puhoi-cottage-tea-bucket/homebuttonMenu.jpg' alt="Image of the Puhoi Cottage, built in 1907." />
      </Col> */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} >
        <div className="title">
          <h2 className="title-services-left">1907</h2>
          <div className="line"/>
        </div>
        <p>The Cottage was built in 1907 along the Puhoi River.</p>
        <p>The property at the time consisted of the grounds as we know them today as well the neighbouring land; which has since been subdivided and now hosts the Puhoi Fire Station, and 48 Ahuroa Road.</p>
        <p>The land was used both as a farm - complete with a cow - and an orchard, with trees resplendent with citrus fruits. </p>
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} className="order-md-3">
        <div className="title">
          <div className="line"/>
          <h2 className="title-services-right">1972</h2>
        </div>
        <p>Tea and scones were first sold from the Cottage in 1972.</p>
        <p>Operating from the front garden, the original proprieter started offering the high teas for which we are known best.</p>
        <p>Sourcing the cream from her cow and fresh strawberries from her garden, the Tea Rooms quickly became a hit.</p>
      </Col>
    </Row>
    <Row>
      {/* <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} className="hero-image-container order-md-4">
        <Image fluid className="services-img" src='https://storage.googleapis.com/puhoi-cottage-tea-bucket/homebuttonMenu.jpg' alt="Image of the Puhoi Cottage Tea Rooms, open for business in 1972." />
      </Col> */}
      
    </Row>
    <Row>
      {/* <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} className="hero-image-container order-md-first">
        <Image fluid className="services-img" src='https://storage.googleapis.com/puhoi-cottage-tea-bucket/homebuttonMenu.jpg' alt="Image of the Tea Rooms, circa 2015." />
      </Col> */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}}>
        <div className="title subtitle">
          <h2 className="title-services-left">2019</h2>
          <div className="line"/>
        </div>
        <p>Over time, the Puhoi Cottage Tea Rooms had grown both in standing and in their offering. The tea and scones, still as popular as always, were joined by an extended offering, including breakfast and lunch dishes - and cold drinks served straight from the fridge.</p>
        <p>In 2019, the Tea Rooms entered a thirty month hiatus, ceasing operation just before the pandemic ran its course.</p>
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}}>
        <div className="title subtitle">
          <div className="line"/>
          <h2 className="title-services-right">2022</h2>
        </div>
        <p>Over the past fifty years the Cottage and business have changed hands numerous times, with each owner leaving their own mark on the history of the cottage.</p>
        <p>Now, in 2022, the Tea Rooms have reopened for business. <br></br> Having lived next door to the Tea Rooms for over a decade - on land that once hosted citrus and willow trees - Steven and Stephanie Roach have long had their own ideas on the perfect Devonshire Tea.</p>
        <p>With new owners comes a newly renovated kitchen (as well as a new scone recipe, hint hint) serving sweet treats, wholesome lunches and other delights in the heart of the Puhoi historic village.</p>
      </Col>
    </Row>
    <Row>
      {/* <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} className="hero-image-container order-md-last">
        <Image fluid className="services-img" src='https://storage.googleapis.com/puhoi-cottage-tea-bucket/homebuttonMenu.jpg' alt="Image of the Puhoi Cottage, reopened in 2022." />
      </Col> */}
      
    </Row>
  </Container>
}

export default About