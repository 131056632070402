// SVG Components seperated into their own files to then import into the App file.
// This aids readability and does not clog up the window with SVG path code, see below.
// This is the smaller version of the primary marque, with a different layout of both the logo and tagline.
function TitleLogoMobile() {
    return <svg className="d-block d-md-none" width="270px" height="60px" viewBox="0 0 279.65 62.1">
    <g>
      <path className="cls-2" d="M41.08,24.72c-3.06,0-6.39-2.44-7.36-3.21-1.24,.98-6.3,4.68-9.77,2.57-1.37-.83-2.09-1.76-2.14-2.75-.02-.46,.1-.87,.27-1.21-2.08,.22-5.09-.48-6.12-2.14-.52-.84-.72-2.2,1.18-4-1.9-1.8-1.71-3.16-1.18-4,1.03-1.66,4.04-2.36,6.12-2.14-.17-.34-.3-.74-.27-1.21,.05-1,.77-1.92,2.14-2.75,3.47-2.11,8.54,1.59,9.77,2.57,1.24-.98,6.3-4.68,9.77-2.57,1.37,.83,2.09,1.76,2.14,2.75,.03,.46-.1,.87-.27,1.21,2.07-.21,5.11,.51,6.12,2.13,.53,.84,.72,2.2-1.18,4,1.9,1.8,1.71,3.16,1.18,4-1.02,1.63-4.06,2.34-6.12,2.13,.17,.34,.3,.75,.28,1.21-.05,1-.77,1.92-2.14,2.75-.75,.45-1.57,.64-2.41,.64Zm-14.99-6.99l-2.68,2.05s-.82,.65-.77,1.52c.04,.69,.64,1.41,1.74,2.07,3.38,2.05,9.01-2.67,9.06-2.71l.27-.23,.27,.23c.06,.05,5.68,4.77,9.06,2.71,1.1-.67,1.7-1.39,1.74-2.08,.05-.86-.77-1.5-.77-1.51l-2.71-2.08,3.14,1.36c1.48,.64,5.31,.11,6.33-1.52,.69-1.11-.36-2.37-1.37-3.24l-.37-.32,.37-.32c1.01-.87,2.06-2.13,1.37-3.24-1.02-1.63-4.85-2.16-6.33-1.52l-3.14,1.36,2.72-2.08s.82-.65,.77-1.52c-.04-.69-.64-1.41-1.74-2.07-3.38-2.05-9.01,2.67-9.06,2.71l-.27,.23-.27-.23c-.06-.05-5.69-4.77-9.06-2.71-1.1,.67-1.7,1.38-1.74,2.07-.05,.86,.77,1.51,.78,1.52l2.58,2-3-1.29c-1.64-.7-5.39,0-6.33,1.52-.69,1.11,.36,2.37,1.37,3.23l.37,.32-.37,.32c-1.01,.86-2.06,2.13-1.37,3.23,.94,1.52,4.69,2.22,6.33,1.52l3.1-1.33Z"/>
      <path className="cls-2" d="M41.16,27.96c-2.79,0-5.55-1.35-7.44-2.54-1.89,1.19-4.65,2.54-7.44,2.54-1.5,0-2.86-.37-4.06-1.1-1.69-1.03-2.82-2.28-3.34-3.72-3.15-.59-5.43-2.21-6.26-4.47-.4-1.08-.64-2.75,.41-4.69-1.05-1.94-.8-3.61-.41-4.69,.84-2.26,3.11-3.88,6.26-4.47,.53-1.44,1.65-2.69,3.35-3.72,1.2-.73,2.57-1.1,4.06-1.1,2.79,0,5.55,1.35,7.44,2.54,1.89-1.19,4.65-2.54,7.44-2.54,1.49,0,2.86,.37,4.06,1.1,1.69,1.03,2.82,2.28,3.35,3.72,3.15,.59,5.43,2.21,6.26,4.47,.4,1.07,.64,2.75-.41,4.69,1.05,1.94,.8,3.61,.41,4.69-.84,2.26-3.11,3.88-6.26,4.47-.53,1.44-1.65,2.7-3.35,3.72-1.2,.73-2.57,1.1-4.06,1.1Zm-7.44-3.54l.23,.15c1.8,1.17,4.51,2.55,7.21,2.55,1.34,0,2.56-.33,3.63-.98,1.61-.98,2.64-2.16,3.06-3.5l.08-.25,.25-.04c2.93-.49,5.12-1.98,5.86-3.98,.35-.94,.55-2.44-.46-4.19l-.12-.21,.12-.21c1.02-1.75,.81-3.24,.46-4.19-.74-2-2.93-3.49-5.86-3.98l-.25-.04-.08-.25c-.42-1.35-1.45-2.53-3.06-3.5-1.07-.65-2.29-.98-3.63-.98-2.7,0-5.41,1.39-7.21,2.55l-.23,.15-.23-.15c-1.8-1.17-4.52-2.55-7.21-2.55-1.34,0-2.56,.33-3.63,.98-1.61,.98-2.64,2.16-3.06,3.5l-.08,.25-.25,.04c-2.93,.49-5.12,1.98-5.86,3.98-.35,.94-.55,2.44,.46,4.19l.12,.21-.12,.21c-1.02,1.75-.81,3.24-.46,4.19,.74,2,2.93,3.49,5.86,3.98l.25,.04,.08,.25c.42,1.35,1.45,2.52,3.06,3.5,1.07,.65,2.29,.98,3.63,.98,2.7,0,5.41-1.39,7.21-2.55l.23-.15Z"/>
      <path className="cls-2" d="M44.64,39.98v-.84c2.25,0,4.12-.85,5.27-2.39,1.49-1.99,1.69-5.01,.58-8.73-.17-.53,1.04-3.02,3.6-7.4l.72,.42c-1.69,2.88-3.44,6.18-3.51,6.77,1.48,5.01,.42,7.9-.73,9.44-1.31,1.76-3.42,2.73-5.94,2.73Zm6.66-12.22s0,0,0,.02c0,0,0-.01,0-.02Z"/>
      <path className="cls-2" d="M29.02,44.43c-1.23,0-2.36-.34-3.4-1.01-3.84-2.51-4.94-9.01-4.66-11.77,.26-2.29-2.37-8.74-2.4-8.8l.78-.32c.11,.27,2.75,6.71,2.46,9.21-.27,2.58,.74,8.66,4.28,10.97,2.01,1.31,4.51,1.14,7.43-.52l.21-.12,.21,.12c2.83,1.63,5.26,1.83,7.21,.59,3.48-2.21,4.54-8.39,4.32-11.05-.24-2.49,2.52-8.93,2.64-9.2l.77,.33c-.03,.06-2.79,6.51-2.58,8.8,.24,2.93-.89,9.41-4.71,11.84-2.18,1.39-4.83,1.24-7.87-.45-1.69,.93-3.26,1.39-4.7,1.39Z"/>
      <rect className="cls-2" x="33.3" y="24.92" width=".84" height="17.34"/>
      <path className="cls-2" d="M22.8,39.98c-2.52,0-4.63-.97-5.94-2.73-1.15-1.54-2.21-4.43-.73-9.44-.07-.6-1.82-3.89-3.51-6.77l.72-.42c2.56,4.38,3.77,6.87,3.59,7.41-1.1,3.71-.9,6.73,.59,8.72,1.15,1.54,3.02,2.39,5.27,2.39v.84Zm-6.66-12.2s0,0,0,.01c0,0,0,0,0-.01Z"/>
      <rect className="cls-2" x="33.3" y="42.27" width=".84" height="11.39"/>
      <path className="cls-2" d="M47.75,62.1c-.63,0-1.25-.04-1.88-.12-7.7-1.01-12.32-7.8-12.51-8.09l-.21-.31,.28-.24c13.06-11.25,27.27,2.22,27.41,2.36l.33,.32-.34,.3c-4.36,3.85-8.76,5.79-13.08,5.79Zm-13.47-8.38c.99,1.33,5.29,6.59,11.71,7.43,4.57,.6,9.25-1.14,13.94-5.16-2.19-1.97-14.2-11.79-25.65-2.27Z"/>
      <path className="cls-2" d="M19.68,62.1c-4.33,0-8.72-1.94-13.08-5.79l-.34-.3,.33-.32c.14-.14,14.34-13.61,27.41-2.36l.28,.24-.21,.31c-.19,.29-4.81,7.09-12.51,8.09-.62,.08-1.25,.12-1.88,.12Zm-12.18-6.11c4.69,4.02,9.37,5.76,13.94,5.16,6.42-.84,10.72-6.1,11.71-7.43-11.45-9.52-23.45,.3-25.65,2.27Z"/>
      <rect className="cls-2" x="33.3" y="7" width=".84" height="13.97"/>
      <rect className="cls-2" x="17.76" y="13.61" width="31.91" height=".84"/>
      <rect className="cls-2" x="33.72" y="53.24" width="11.8" height=".84"/>
      <rect className="cls-2" x="44.36" y="46.76" width="8.5" height=".84" transform="translate(-15.98 26.76) rotate(-26.6)"/>
      <rect className="cls-2" x="21.92" y="53.24" width="11.79" height=".84"/>
      <rect className="cls-2" x="18.4" y="42.92" width=".84" height="8.5" transform="translate(-31.79 42.88) rotate(-63.4)"/>
      <path className="cls-2" d="M54.74,52.39l-.14-.07c-3.84-2.05-7.59-2.92-11.16-2.58l-.68,.06,.25-.64c5.8-14.72,23.81-11.02,23.99-10.98l.44,.1-.13,.43c-2.29,7.62-6.46,12.21-12.42,13.64l-.15,.04Zm-9.79-3.56c3.2,0,6.53,.9,9.9,2.67,5.44-1.36,9.32-5.59,11.52-12.57-2.73-.45-17.06-2.17-22.32,9.93,.3-.02,.6-.02,.91-.02Z"/>
      <path className="cls-2" d="M12.7,52.39l-.15-.04c-5.95-1.42-10.13-6.01-12.42-13.64l-.13-.43,.44-.1c.18-.04,18.18-3.74,23.99,10.98l.25,.64-.68-.06c-3.57-.34-7.32,.53-11.16,2.58l-.14,.07ZM1.07,38.93c2.2,6.98,6.07,11.21,11.52,12.57,3.69-1.94,7.32-2.83,10.81-2.65-5.27-12.09-19.59-10.37-22.32-9.93Z"/>
    </g>
    <g>
      <path className="cls-1" d="M96.36,23.81h2.48c.16,0,.29-.1,.29-.48V4.52c0-.38-.13-.45-.29-.45h-2.48v-.7h7.93c4.58,0,7.1,1.94,7.1,5.76s-2.39,5.92-7.16,5.92h-4.27v8.28c0,.38,.13,.48,.32,.48h2.45v.7h-6.37v-.7Zm3.6-9.45h4.27c4.23,0,6.3-1.66,6.3-5.22s-2.1-5.06-6.24-5.06h-4.33V14.35Z"/>
      <path className="cls-1" d="M128.77,24.51c-1.02,0-2.39,.1-2.93,.16v-4.81c-.95,3.06-3.02,4.94-5.76,4.94-2.9,0-4.62-1.43-4.62-4.78V10.31c0-.35-.1-.41-.29-.41h-2.48v-.7h3.63v10.76c0,2.99,1.4,4.11,3.79,4.11,3.44,0,5.7-3.44,5.7-8.02v-5.73c0-.35-.13-.41-.29-.41h-2.48v-.7h3.6v14.1c0,.38,.1,.51,.32,.51h2.48v.7h-.67Z"/>
      <path className="cls-1" d="M134.09,13.94c.99-3.15,3.09-5.03,5.83-5.03,3.12,0,4.65,1.72,4.65,4.94v9.52c0,.38,.1,.45,.29,.45h2.48v.7h-6.27v-.7h2.36c.19,0,.29-.06,.29-.45V13.91c0-2.83-1.18-4.27-3.82-4.27-3.44,0-5.79,3.44-5.79,8.69v5.03c0,.38,.1,.45,.29,.45h2.48v.7h-6.4v-.7h2.48c.19,0,.29-.06,.29-.45V4.58c0-.41-.1-.51-.29-.51h-2.52v-.7h.67c1.05,0,2.42-.1,2.99-.16V13.94Z"/>
      <path className="cls-1" d="M148.6,16.84c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M164.75,23.81h2.48c.19,0,.29-.06,.29-.45V10.85c0-.73-.13-.95-.54-.95h-2.23v-.7h.67c1.05,0,2.42-.13,2.96-.16v14.32c0,.38,.1,.45,.29,.45h2.48v.7h-6.4v-.7Zm2.23-19.32c0-.48,.38-.86,.89-.86s.89,.38,.89,.86c0,.51-.38,.89-.89,.89s-.89-.38-.89-.89Z"/>
      <path className="cls-1" d="M180.16,14c0-6.88,4.3-10.92,9.61-10.92,3.76,0,6.62,1.97,7.64,5.22l.06-4.94h.67v7.42h-.64c-.96-4.9-3.88-6.94-7.7-6.94-4.84,0-8.79,3.72-8.79,10.06v.1c0,6.43,3.72,10.03,8.66,10.03,3.82,0,7.1-1.69,7.93-6.4l.83,.19c-.86,4.84-4.3,6.97-8.76,6.97-5.44,0-9.52-4.04-9.52-10.79Z"/>
      <path className="cls-1" d="M200.31,16.84c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M218.84,21.23V9.9h-2.61v-.64l2.13-.06c.41,0,.48-.1,.51-.51l.13-2.77h.73v3.28h3.98v.7h-4.04v11.33c0,1.97,.76,2.8,2.48,2.8,.89,0,1.4-.19,1.88-.38v.73c-.35,.13-.99,.35-1.91,.35-2.26,0-3.28-1.05-3.28-3.5Z"/>
      <path className="cls-1" d="M227.66,21.23V9.9h-2.61v-.64l2.13-.06c.41,0,.48-.1,.51-.51l.13-2.77h.73v3.28h3.98v.7h-4.04v11.33c0,1.97,.76,2.8,2.48,2.8,.89,0,1.4-.19,1.88-.38v.73c-.35,.13-.99,.35-1.91,.35-2.26,0-3.28-1.05-3.28-3.5Z"/>
      <path className="cls-1" d="M245.23,14.23c0-3.15-1.5-4.65-4.68-4.65-2.58,0-4.43,1.15-4.94,2.64,.64,0,.99,.35,.99,.83s-.38,.73-.86,.73c-.54,0-.96-.48-.96-1.15,0-1.97,2.64-3.72,5.76-3.72,3.69,0,5.54,1.78,5.54,5.32v9.14c0,.29,.06,.45,.29,.45h2.48v.7h-3.44c-.06-.73-.1-1.62-.13-3.98-1.05,3.12-3.92,4.27-6.11,4.27-2.87,0-4.74-1.66-4.74-4.11,0-3.18,3.06-4.87,10.79-5.38v-1.08Zm0,1.75c-7.13,.48-9.96,1.75-9.96,4.71,0,2.04,1.59,3.41,3.98,3.41,3.28,0,5.99-2.52,5.99-6.46v-1.65Z"/>
      <path className="cls-1" d="M262.11,14.45c0,3.18-2.42,5.35-5.98,5.35-1.08,0-2.04-.22-2.93-.64-1.4,.35-2.29,1.15-2.29,2.2,0,1.11,.99,1.75,2.77,1.75h5.51c3.25,0,4.97,1.27,4.97,3.47,0,2.93-3.41,4.52-8.12,4.52-4.46,0-6.94-1.18-6.94-3.57,0-1.94,1.69-3.22,4.14-3.79h-.13c-1.78-.16-2.9-.99-2.9-2.35,0-1.08,.89-2.13,2.32-2.58-1.43-.96-2.29-2.52-2.29-4.43,0-3.12,2.58-5.48,5.98-5.48,2.23,0,4.04,.96,5.03,2.55,.32-2.1,1.37-2.96,2.39-2.96,.6,0,1.08,.41,1.08,1.02,0,.51-.38,.86-.89,.86-.48,0-.76-.35-.76-.8,0-.03,0-.13,.03-.16-.64,.32-1.21,1.08-1.5,2.71,.32,.64,.51,1.46,.51,2.32Zm-6.88,9.42c-2.99,.32-5.35,1.62-5.35,3.57,0,2.04,2.07,2.96,6.14,2.96s7.32-1.24,7.32-3.79c0-1.78-1.4-2.74-4.17-2.74h-3.95Zm6.05-9.39v-.03c0-2.9-2.07-4.87-5.06-4.87s-5.13,2.04-5.13,4.81v.03c0,2.83,1.97,4.68,5.03,4.68s5.16-1.78,5.16-4.62Z"/>
      <path className="cls-1" d="M266.37,16.81v.13c0,4.01,2.55,7.16,6.46,7.16,3.37,0,5.38-1.72,6.02-4.55l.76,.13c-.67,3.09-2.99,5.13-6.78,5.13-4.36,0-7.32-3.41-7.32-7.93s2.93-7.96,7.42-7.96c3.98,0,6.72,2.96,6.72,7.19v.7h-13.27Zm12.42-.8c0-3.79-2.32-6.43-5.89-6.43-3.82,0-6.24,2.8-6.49,6.53h12.54l-.16-.1Z"/>
      <path className="cls-1" d="M102.25,58.3h2.48c.19,0,.29-.1,.29-.48v-19.26h-6.91c-.32,0-.48,.1-.51,.35l-.03,3.91h-.73v-4.97h17.29v4.97h-.73l-.06-3.91c0-.29-.16-.35-.48-.35h-6.97v19.26c0,.38,.1,.48,.29,.48h2.48v.7h-6.4v-.7Z"/>
      <path className="cls-1" d="M113.9,51.3v.13c0,4.01,2.55,7.16,6.46,7.16,3.37,0,5.38-1.72,6.02-4.55l.76,.13c-.67,3.09-2.99,5.13-6.78,5.13-4.36,0-7.32-3.41-7.32-7.93s2.93-7.96,7.42-7.96c3.98,0,6.72,2.96,6.72,7.19v.7h-13.27Zm12.42-.8c0-3.79-2.32-6.43-5.89-6.43-3.82,0-6.24,2.8-6.49,6.53h12.54l-.16-.1Z"/>
      <path className="cls-1" d="M140.33,48.72c0-3.15-1.5-4.65-4.68-4.65-2.58,0-4.43,1.15-4.94,2.64,.64,0,.99,.35,.99,.83s-.38,.73-.86,.73c-.54,0-.96-.48-.96-1.15,0-1.97,2.64-3.72,5.76-3.72,3.69,0,5.54,1.78,5.54,5.32v9.14c0,.29,.06,.45,.29,.45h2.48v.7h-3.44c-.06-.73-.1-1.62-.13-3.98-1.05,3.12-3.92,4.27-6.11,4.27-2.87,0-4.74-1.66-4.74-4.11,0-3.18,3.06-4.87,10.79-5.38v-1.08Zm0,1.75c-7.13,.48-9.96,1.75-9.96,4.71,0,2.04,1.59,3.41,3.98,3.41,3.28,0,5.99-2.52,5.99-6.46v-1.65Z"/>
      <path className="cls-1" d="M167.07,55.02c.16,2.67,.6,3.28,2.48,3.28h.54v.7h-.51c-2.61,0-3.25-1.18-3.41-3.92-.22-4.07-1.66-6.05-6.14-6.05h-4.04v8.79c0,.38,.13,.48,.32,.48h2.45v.7h-6.37v-.7h2.48c.16,0,.29-.1,.29-.48v-18.81c0-.38-.13-.45-.29-.45h-2.48v-.7h8.24c4.74,0,7.13,1.82,7.13,5.44,0,2.96-1.46,4.87-5,5.41,2.99,.73,4.11,2.93,4.3,6.3Zm-6.72-6.72c4.62,0,6.56-1.72,6.56-5s-2.01-4.74-6.27-4.74h-4.65v9.74h4.36Z"/>
      <path className="cls-1" d="M171.21,51.33c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M187.89,51.33c0-4.68,3.02-7.93,7.42-7.93s7.29,3.22,7.29,7.93-3.02,7.96-7.35,7.96-7.35-3.25-7.35-7.96Zm13.88,.06v-.1c0-4.3-2.61-7.23-6.46-7.23s-6.56,2.96-6.56,7.23v.1c0,4.27,2.58,7.19,6.49,7.19s6.53-2.93,6.53-7.19Z"/>
      <path className="cls-1" d="M216.93,47.83c.76-2.93,2.39-4.43,4.9-4.43,2.86,0,4.52,1.82,4.52,5v9.45c0,.32,.06,.45,.29,.45h2.48v.7h-6.3v-.7h2.39c.19,0,.29-.13,.29-.45v-9.39c0-2.8-1.31-4.33-3.69-4.33-3.06,0-4.74,2.64-4.74,7.64v6.08c0,.32,.1,.45,.32,.45h2.48v.7h-6.4v-.7h2.45c.22,0,.29-.13,.29-.45v-9.39c0-2.8-1.27-4.33-3.66-4.33-3.06,0-4.74,2.64-4.74,7.64v6.08c0,.32,.06,.45,.29,.45h2.51v.7h-6.43v-.7h2.48c.22,0,.29-.13,.29-.45v-12.99c0-.35-.06-.48-.29-.48h-2.48v-.7h.64c1.08,0,2.45-.13,2.99-.16l-.06,4.11c.76-2.8,2.36-4.23,4.84-4.23,2.64,0,4.2,1.59,4.36,4.43Z"/>
      <path className="cls-1" d="M230.68,54.23h.73v3.15c1.08,.7,2.83,1.18,4.58,1.18,2.9,0,4.49-1.27,4.49-3.44,0-2.39-2.04-3.02-4.58-3.57-2.58-.51-5.13-1.21-5.13-4.04,0-2.45,2.04-4.11,5.16-4.11,2.07,0,3.57,.51,4.93,1.4v3.57h-.73v-3.12c-1.15-.73-2.39-1.15-4.2-1.15-2.71,0-4.36,1.27-4.36,3.34,0,2.48,2.23,2.9,4.68,3.37,2.83,.57,5.06,1.53,5.06,4.27,0,2.58-1.94,4.2-5.32,4.2-1.97,0-3.98-.57-5.32-1.46v-3.6Z"/>
    </g>
  </svg>
}

export default TitleLogoMobile