import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import {Row, Col, Container} from 'react-bootstrap';
import { FormComponent } from '../components/FormComponent';
import { FormComponentHighTea } from '../components/FormComponentHighTea';
import { FormComponentFunction } from '../components/FormComponentFunction';
import { FormComponentVenue } from '../components/FormComponentVenue';

class Bookings extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    // Following code imported from the Bootstrap website to add form validation.
      window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
          form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          }, false);
        });
      }, false);
  }
  render() {
    return <Container fluid>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h2>BOOKINGS</h2>
      <div className="line"/>
    </Col>
  </Row>
  {/* Preamble */}
  <Row>
    <Col xs={12} sm={{span: 8, offset: 2}}>
      <p className="text-center">For our Devonshire Teas and other menu items, walk-ins are welcome!</p>
      <p className="text-center">For specific enquires please call or text us on <a href="tel:0212882554">021 2882 554</a>.</p>
    </Col>
  </Row>
  {/* Booking Form */}
  {/* Seperated into it's own component as utilizing Email.JS requires a functional component to use React hooks. */}
  {/* Hooks cannot be used in Class components, thus the separation. */}
  <Routes>
    <Route path="hightea" element={<FormComponentHighTea />} />
    <Route path="function" element={<FormComponentFunction />} />
    <Route path="venue" element={<FormComponentVenue />} />
    <Route path="" element={<FormComponent />}/>
  </Routes>
  </Container>
  }
}

export default Bookings