import {Row, Col, Container} from 'react-bootstrap'
import MenuItem from '../components/MenuItem'
import ItemTabbed from '../components/ItemTabbed'

function Menu() {
    return <Container fluid>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h2>MENU</h2>
      <div className="line"/>
    </Col>
    </Row>
    <Row>
      {/* Prelude */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} xl={{span: 3, offset: 3}}>
      <p>
      Our menu reflects the history of the Cottage, highlighted by tea and scones lashed with cream and jam. <br />
      If that's not your cup of tea, we offer a range of family-inspired fare for all.
      </p>
      </Col>
      {/* Link to Menu PDF */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} xl={{span: 3, offset: 0}}>
      <p>At the Tea Rooms, we love locally grown produce, and pride ourselves on our local suppliers.<br></br><span className="menu-specials-highlight">Specials change seasonally!</span></p><p><a rel="noreferrer" target="_blank" href="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Resources/Puhoi_Cottage_menu_web.pdf"><span>Latest Menu</span></a></p>
      </Col>
      {/* Food */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 2}} xl={{span: 4, offset: 2}}>
        <div className="header-wrapper d-flex flex-row align-items-center">
          <h3>Food</h3>
          <div className="line"/>
        </div>
        <MenuItem 
        title="Devonshire Cream Tea (our specialty)" 
        price="14" 
        description="Two scones served with cream and strawberry jam, alongside a pot of tea of your choice."
        amends=""
        />
        <MenuItem 
        title="Soup of the Day" 
        price="12" 
        description="Served with bread and butter."
        amends="Varies by day"
        />
        <MenuItem 
        title="Vegetarian Quiche" 
        price="10" 
        description="Slice of french egg tart, seasonal vegetables."
        amends=""
        />
        <MenuItem 
        title="Ploughman’s Platter" 
        price="25" 
        description="Selection of meats, cheeses, olives, pickle and nuts served with crackers and bread."
        amends=""
        />
        <MenuItem 
        title="Toasted Sandwich " 
        price="10" 
        description="Any two fillings – ham, cheese, tomato, onion, pickle."
        amends="Additional fillings + 50 cents each - Served with a salad mix."
        />
        <MenuItem 
        title="Sausage Roll" 
        price="10" 
        description="Served with relish, mustard or tomato sauce."
        amends="Served with a salad mix."
        />
        <MenuItem 
        title="Pie of the Day" 
        price="10" 
        description="A hearty choice of Mince and Cheese Pie or Spinach and Feta Roll."
        amends="Served with a salad mix and relish."
        />
        <MenuItem 
        title="Hot Dog" 
        price="10" 
        description="Frankfurter topped with tomato sauce and mustard."
        amends="Add caramelised onion and pickle + $2"
        />
        <MenuItem 
        title="Salad Bowl" 
        price="13" 
        description="Seasonal mix - add tuna, or vegetarian."
        amends=""
        />
        <MenuItem 
        title="High Tea" 
        price="40 per person" 
        description="A premium selection of sandwiches, scones, and sweet treats."
        amends="Served with your choice of tea – Bookings essential."
        />
      </Col>
      {/* Drinks */}
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 0}} xl={{span: 4, offset: 0}}>
        <div className="header-wrapper d-flex flex-row align-items-center">
        <h3>Drink</h3>
        <div className="line"/>
        </div>
        {/* Menu Category Category */}
        <h5>Hot Drinks</h5>
        {/* One Menu Item */}
        <MenuItem 
        title="Pot of Tea" 
        price="6" 
        />
        <ItemTabbed 
        description="Black"
        amends="
        English Breakfast, 
        Earl Grey, 
        Spiced Cinnamon, 
        Chai Nepal Masala 
        "
        />
        <ItemTabbed
        description="Green"
        amends="
        Jade Sencha, 
        Japanese Lime"
        />
        <ItemTabbed
        description="Fruit"
        amends="
        Peach Rose,
        Berry Garden"
        />
        <ItemTabbed
        description="Herbal"
        amends="Ginger Kawakawa Spice,
        Rooibos Madagascar Vanilla,
        Pure Peppermint"
        />
        <MenuItem 
        title="Plunger Coffee" 
        price="6" 
        description="Karajoz"
        amends=""
        />
        <h5>Cold Drinks</h5>
        <MenuItem 
        title="Iced Coffee Frappe" 
        price="8" 
        />
        <MenuItem 
        title="Mixed Berry Smoothie" 
        price="8" 
        />
        <MenuItem 
        title="Mango Smoothie" 
        price="8" 
        />
        <MenuItem 
        title="Antipodes Sparkling Water" 
        price="8" 
        />
        <MenuItem 
        title="Water" 
        price="0" 
        />
        <h5>Soft Drinks</h5>
        <MenuItem 
        title="Coca-Cola" 
        price="3" 
        />
        <MenuItem 
        title="Coca-Cola Zero Sugar" 
        price="3" 
        />
        <MenuItem 
        title="Sprite" 
        price="3" 
        />
        <MenuItem 
        title="L&P" 
        price="3" 
        />
        <MenuItem 
        title="Bundaberg Ginger Beer" 
        price="5" 
        />
      </Col>
    
  </Row>
  </Container>
}

export default Menu