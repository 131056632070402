import {Row, Col, Container} from 'react-bootstrap'

function Contact() {
    return <Container fluid>
    <Row>
    <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
      <div className="line"/>
      <h2>CONTACT</h2>
      <div className="line"/>
    </Col>
    <Col xs={12} sm={{span: 3, offset: 2}} md={{span: 3, offset: 3}} lg={{span: 2, offset: 4}}>
      {/* Phone Number */}
      <h5>Phone Number</h5>
      <p><a href="tel:09-4220480">09 422 0480</a> (landline)</p>
      <p><a href="tel:0212882554">021 2882 554</a> (mobile)</p>
      {/* Email Address */}
      <h5>Email Address</h5>
      <p><a href="mailto:info@puhoitea.com">info@puhoitea.com</a></p>
    </Col>
    <Col xs={12} sm={{span: 3, offset: 1}}>
      {/* Street Address */}
      <h5>Address</h5>
      <p>50 Ahuroa Road <br></br>
      Puhoi <br></br>
      Auckland <br></br>
      New Zealand</p>
    </Col>
    <Col className="contact-maps-container" xs={12} sm={{span: 8, offset: 2}}>
      {/* Google Maps */}
      <iframe width="100%" title="Google Map of the Cottage and surrounding addresses." height="300px" src="https://maps.google.com/maps?q=Puhoi%20Cottage%20Tea%20Rooms&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no"></iframe>
    </Col>
  </Row>
  </Container>
}

export default Contact