import React from "react"

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div>
        <div className="menu-item-container d-flex flex-row justify-content-between">
            <h6 className="menu-item-title">{this.props.title}</h6>
            <h6 className="menu-item-price">${this.props.price}</h6>
        </div>
        <p className="menu-item-description">{this.props.description}</p>
        <p className="menu-item-amends">{this.props.amends}</p>
        </div>
    }
}

export default MenuItem