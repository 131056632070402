import {Row, Col, Container, Image, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Services() {
    return <Container fluid>
    <Row>
      <Col className="title" xs={12} sm={{span: 8, offset: 2}}>
        <div className="line"/>
        <h2>SERVICES</h2>
        <div className="line"/>
      </Col>
    </Row>
    <Row>
      {/* Preamble */}
      <Col xs={12} sm={{span: 6, offset: 3}}>
        <p className="text-center">In addition to our sumptuous menu items and variety of teas and drinks, we also offer other services at the Tea Rooms.</p>
      </Col>
    </Row>
    <Row className="services-row">
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 3, offset: 2}} lg={{span: 3, offset: 3}}>
        <Image fluid className="services-img" src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/servicesHighTea.webp" alt="Image for the High Tea service." /> 
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 5, offset: 0}} lg={{span: 4, offset: 0}}>
        <div className="title">
          <h2 className="title-morethanone title-services-left">HIGH TEA</h2>
          <div className="line"/>
        </div>
        <p>As one of New Zealands oldest teahouses, we are well equipped to make your high tea experience as authentic as possible, in keeping with the Devonshire tradition while adding the quintessentially Kiwi warmth and hospitality.</p>
        <p>Enjoy a beautiful high tea in our historic cottage or out in the surrounding gardens under shade. (weather permitting)</p>
        <p>Our High Teas includes savouries, scones, and petits fours (sweet treats), as well as a pot of freshly brewed tea.</p>
        <p className="services-pricing">$40 per person - Bookings essential! <br></br>For parties of 8 or more people, please text or call <a href="tel:0212882554">021 2882 554</a>.</p>
        <Link to="/bookings/hightea"><Button>Click here to make a booking</Button></Link>
      </Col>
    </Row>
    <Row className="services-row">
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 3, offset: 2}}  lg={{span: 3, offset: 3}}>
      <Image fluid className="services-img" src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/servicesFunction.webp" alt="Image for the Function service." />
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 5, offset: 0}} lg={{span: 4, offset: 0}}>
        <div className="title">
          <h2 className="title-morethanone title-services-left">FUNCTION HOSTING</h2>
          <div className="line"/>
        </div>
        <p>Situated next to the scenic Puhoi River, our cottage and gardens are the ideal location for your gathering.</p>
        <p>On top of our regular menu, we can help with all catering requirements.</p>
        <p>Please note that our regular cafe operations will continue during your gathering. For exclusive use of the venue and surrounds please see our 'Venue Hire' option.</p>
        <p>We are in the process of acquiring our on-license so we can expand our offering in future.</p>
        <p className="services-pricing">We require a weeks notice in the event of a booking. Price upon request - For specific enquiries please text or call <a href="tel:0212882554">021 2882 554</a>.</p>
        <Link to="/bookings/function"><Button alt="">Click here to make a booking</Button></Link>
      </Col>
    </Row>
    <Row className="services-row">
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 3, offset: 2}} lg={{span: 3, offset: 3}}>
      <Image fluid className="services-img" src="https://storage.googleapis.com/puhoi-cottage-tea-bucket/Images/servicesVenue.webp" alt="Image for the Venue service." />
      </Col>
      <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 5, offset: 0}} lg={{span: 4, offset: 0}}>
        <div className="title">
          <h2 className="title-morethanone title-services-left">VENUE HIRE</h2>
          <div className="line"/>
        </div>
        <p>We would love to hire out our entire Cottage and grounds to you.</p>
        <p>On top of our 'Function Hosting' service, this option promises an exclusive venue, situated in the beautiful Puhoi countryside, complete with friendly, free-roaming chickens and sheep.</p>
        <p>The Cottage and surrounds are yours to suit your event, make your day special, or whatever else you need from us.</p>
        <p>Our hospitality and amenities will allow you to host the perfect event, gathering or occasion.</p>
        <p>We are in the process of acquiring our on-license so we can expand our offering in future.</p>
        <p className="services-pricing">We require a weeks notice in the event of a booking. Price upon request - For specific enquiries please text or call <a href="tel:0212882554">021 2882 554</a>.</p>
        <Link to="/bookings/venue"><Button>Click here to make a booking</Button></Link>
      </Col>
    </Row>
  </Container>
}

export default Services