import { Routes, Route, NavLink, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleLogo from './components/TitleLogo';
import Logo from './components/LogoSVG';
import Blurb from './components/BlurbSVG';
import TitleLogoMobile from './components/TitleLogoMobile';
import Sidebar from './components/Sidebar';
import About from './pages/About';
import Bookings from './pages/Bookings';
import Home from './pages/Home';
import Testimonials from './pages/Testimonials';
import Menu from './pages/Menu';
import Services from './pages/Services';
import Contact from './pages/Contact';
import InstagramButtonSVG from './components/InstagramButtonSVG';
import FacebookButtonSVG from './components/FacebookButtonSVG';
// const About = React.lazy(() => import('./pages/About'));
// const Bookings = React.lazy(() => import('./pages/Bookings'));
// const Home = React.lazy(() => import('./pages/Home'));
// const Media = React.lazy(() => import('./pages/Media'));
// const Menu = React.lazy(() => import('./pages/Menu'));
// const OurStory = React.lazy(() => import('./pages/ourStory'));
// const Services = React.lazy(() => import('./pages/Services'));
// const Contact = React.lazy(() => import('./pages/Contact'));

function App() {
  return (
    // Containers are the elements that have a 12-cell grid.
    // Rows follow on from Containers, and Columns follow from Rows.
    <div className="d-flex flex-column min-vh-100">
      <Container fluid>
        <header>
        <Row>
          <Col md={{span: 8, offset: 2}} className="d-none d-md-flex flex-row title-container justify-content-between justify-content-md-center align-items-center">
            <Link to="/" aria-label="Link to home page."><TitleLogo /></Link>
          </Col>
          <Col xs={{span: 12}} sm={{span: 8, offset: 2}} className="position-fixed d-flex d-md-none flex-row mobile-title-container justify-content-between justify-content-md-center align-items-center">
            <Sidebar />
            <Link to="/" aria-label="Link to home page."><TitleLogoMobile /></Link>
          </Col>
        </Row>
        <Row>
          <Col sm={{span: 8, offset: 2}}>
            <nav>
              <ul className="header-nav d-none d-md-flex flex-row justify-content-between">
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/menu">Menu</NavLink></li>
                <li><NavLink to="/services">Services</NavLink></li>
                <li><NavLink to="/bookings">Bookings</NavLink></li>
                <li><NavLink to="/testimonials">Testimonials</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
                {/* <li><NavLink to="/our-story">Our Story</NavLink></li> */}
                <li><NavLink to="/contact">Contact</NavLink></li>
              </ul>
            </nav>
          </Col>
          <Col sm={{span: 8, offset: 2}} className="clearer d-block d-md-none" />
        </Row>
        </header>
        {/* Page content goes here. */}
        </Container>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/menu" element={<Menu />}/>
          <Route path="/services" element={<Services />}/>
          <Route path="/bookings/*" element={<Bookings />}/>
          <Route path="/testimonials" element={<Testimonials />}/>
          <Route path="/about" element={<About />}/>
          {/* <Route path="/our-story" element={<OurStory />}/> */}
          <Route path="/contact" element={<Contact />}/>
        </Routes>
      
      <footer className="mt-auto container-fluid">
        <Row>
          <Col className="d-none d-lg-flex justify-content-start align-items-center" md={2}>
            <Blurb />
          </Col>
          <Col className="footer-nav-one d-flex align-items-center" xs={4} sm={{span: 2, offset: 2}} lg={{span: 2, offset: 0}}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/menu">Menu</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/bookings">Bookings</Link></li>
            </ul>
          </Col>
          <Col className="footer-nav-two d-flex align-items-center" xs={4} sm={2}>
            <ul>
              <li><Link to="/testimonials">Testimonials</Link></li>
              <li><Link to="/about">About</Link></li>
              {/* <li><Link to="/our-story">Our Story</Link></li> */}
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </Col>
          <Col className="info-wrapper d-none d-lg-flex d-flex flex-column justify-content-center" lg={2}>
            {/* Contact info and address and opening hours */}
            <div className="info-container">
              <p>
                Open 10am to 4pm <br></br>
                Tuesday to Sunday
              </p>
            </div>
            <div className="info-container">
              <p>
                <a target="_blank" rel="noreferrer" href='https://www.google.com/maps/place/Puhoi+Cottage+Tea+Rooms/@-36.5094804,174.6550912,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d211724aacb03:0xe08e8f2530058b60!8m2!3d-36.5094847!4d174.6572799'>
                50 Ahuroa Road <br></br>
                Puhoi, New Zealand
                </a>
              </p>
            </div>
          </Col>
          <Col className="social-container d-flex flex-row justify-content-end align-items-center" xs={4} sm={{span: 2, offset: 2}}md={{span: 2, offset: 2}} lg={{span: 2, offset: 0}}>
              <a aria-label="Link to Facebook page." target="_blank" rel="noreferrer" href='https://www.facebook.com/Puhoi-Cottage-Tearooms-107925871773691/'><FacebookButtonSVG /></a>
              <a aria-label="Link to Instagram page." target="_blank" rel="noreferrer" href='https://www.instagram.com/puhoitea/'><InstagramButtonSVG /></a>
          </Col>
          <Col className="d-none d-sm-flex justify-content-end align-items-center" sm={{span: 2, offset: 0}} md={2} lg={{span: 2, offset: 0}}>
            <Logo />
          </Col>
        </Row>
        
      </footer>
    </div>
  );
}

export default App;
